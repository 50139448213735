/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

const INITIAL_STATE = {
    isLoading: false,
    ifServerFilled: false,
    content: ''
};

export const staticPageReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_STATIC_CONTENT_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_STATIC_CONTENT_FETCH':
            return {
                ...state,
                isLoading: false,
                ifServerFilled: action.payload.ifServerFilled,
                content: action.payload.response
            }
        default:
            return state
    }
};
