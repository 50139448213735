import React, { useEffect, useState } from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// import ReactDom from 'react-dom';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';
// import Loader from './components/Common/Loader';
import Calculator from './components/Calculator';
import './App.css';
import { isBeYeuTemplate } from './config';
import { login, setUserLoggedIn, authorProfileAndPostsFetch, completeAuthorProfileUpdate, getProfileFetch } from './actions/index';
import { generateParamsLogin, gtmSend } from './helpers';

const Loading = () => {
    return(<div dangerouslySetInnerHTML={{__html: `${document.getElementById('root').outerHTML}`}}></div>)
}; 
//home
const HomePageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "homeContainerChunk" */
        './containers/Home'),
    loading: Loading,
    modules: ['homeContainerChunk']
});
//view
const ViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "viewContainerChunk" */
        './containers/View'),
    loading: Loading,
    modules: ['viewContainerChunk']
});
//page
const PageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "pageContainerChunk" */
        './containers/Page'),
    loading: Loading,
    modules: ['pageContainerChunk']
});
//booth
const BoothContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "boothContainerChunk" */
        './containers/Booth'),
    loading: Loading,
    modules: ['boothContainerChunk']
});
//booth hashtag
const BoothHashtagContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "boothHashtagContainerChunk" */
        './containers/Booth/Hashtag'),
    loading: Loading,
    modules: ['boothHashtagContainerChunk']
});
//memory
const MemoryViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "memoryViewContainerChunk" */
        './containers/Booth/View'),
    loading: Loading,
    modules: ['memoryViewContainerChunk']
});
//topic list
const TopicListContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "topicListContainerChunk" */
        './containers/Topic'),
    loading: Loading,
    modules: ['topicListContainerChunk']
});
//topic view
const TopicViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "topicViewContainerChunk" */
        './containers/Topic/View'),
    loading: Loading,
    modules: ['topicViewContainerChunk']
});
//author view
const AuthorViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "authorViewContainerChunk" */
        './containers/Author/View'),
    loading: Loading,
    modules: ['authorViewContainerChunk']
});
//author edit
const AuthorEditContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "authorEditContainerChunk" */
        './containers/Author/Edit'),
    loading: Loading,
    modules: ['authorEditContainerChunk']
});
//search view
const SearchViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "searchViewContainerChunk" */
        './containers/Search'),
    loading: Loading,
    modules: ['searchViewContainerChunk']
});
//static page
const StaticPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "staticPageContainerChunk" */
        './containers/Static'),
    loading: Loading,
    modules: ['staticPageContainerChunk']
});
//tracker page
const TrackerPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "trackerPageContainerChunk" */
        './containers/Tracker/Calendar'),
    loading: Loading,
    modules: ['trackerPageContainerChunk']
});
//tracker pregnancy view
const TrackerPregnancyViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "trackerPregnancyViewContainerChunk" */
        './containers/Tracker/View/Pregnancy'),
    loading: Loading,
    modules: ['trackerPregnancyViewContainerChunk']
});
//tracker baby view
const TrackerBabyViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "trackerBabyViewContainerChunk" */
        './containers/Tracker/View/Baby'),
    loading: Loading,
    modules: ['trackerBabyViewContainerChunk']
});
//404 view
const Template404Container = Loadable({
    loader: () => import(
        /* webpackChunkName: "template404ContainerChunk" */
        './containers/404'),
    loading: Loading,
    modules: ['template404ContainerChunk']
});
//login view
const LoginPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "loginPageContainerChunk" */
        './containers/Login'),
    loading: Loading,
    modules: ['loginPageContainerChunk']
});
//login internal view
const LoginInternalPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "loginInternalPageContainerChunk" */
        './containers/LoginInternal'),
    loading: Loading,
    modules: ['loginInternalPageContainerChunk']
});
//onboarding page
const OnboardingPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "onboardingPageContainerChunk" */
        './containers/Onboarding'),
    loading: Loading,
    modules: ['onboardingPageContainerChunk']
});
//contest list page
const ContestPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "contestPageContainerChunk" */
        './containers/Contest'),
    loading: Loading,
    modules: ['contestPageContainerChunk']
});
//contest view page
const ContestViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "contestViewContainerChunk" */
        './containers/Contest/View'),
    loading: Loading,
    modules: ['contestViewContainerChunk']
});
//tap poll view
const TapPollViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "tapPollViewContainerChunk" */
        './containers/TapPoll'),
    loading: Loading,
    modules: ['tapPollViewContainerChunk']
});
//web view
/*const StaticWebViewContainer = Loadable({
    loader: () => import(
         webpackChunkName: "staticWebViewContainerChunk" 
        './containers/WebView'),
    loading: Loading,
    modules: ['staticWebViewContainerChunk']
});*/
//password reset
const PasswordResetContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "passwordResetContainerChunk" */
        './containers/PasswordReset'),
    loading: Loading,
    modules: ['passwordResetContainerChunk']
});
//unanswered
const UnansweredContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "unansweredContainerChunk" */
        './containers/Unanswered'),
    loading: Loading,
    modules: ['unansweredContainerChunk']
});
//app download
const AppDownloadContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "appDownloadContainerChunk" */
        './containers/Static/AppDownload'),
    loading: Loading,
    modules: ['appDownloadContainerChunk']
});
//landing page
const LandingPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "landingPageContainerChunk" */
        './containers/Static/LandingPage'),
    loading: Loading,
    modules: ['landingPageContainerChunk']
});
//tnc rewards
const TnCRewardsContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "tncRewardsContainerChunk" */
        './containers/WebView/RewardsTnC'),
    loading: Loading,
    modules: ['tncRewardsContainerChunk']
});
//unsubscribe
const UnsubscribeContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "unsubscribeContainerChunk" */
        './containers/Static/Unsubscribe'),
    loading: Loading,
    modules: ['unsubscribeContainerChunk']
});

const VideoContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "videoContainerChunk" */
        './containers/Videos'),
    loading: Loading,
    modules: ['videoContainerChunk']
});

//Reward list page
const RewardPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "rewardPageContainerChunk" */
        './containers/Reward'),
    loading: Loading,
    modules: ['rewardPageContainerChunk']
});
//reward view page
const RewardViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "rewardViewContainerChunk" */
        './containers/Reward/View'),
    loading: Loading,
    modules: ['rewardViewContainerChunk']
});
//COVID !() view
const CovidViewContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "CovidViewContainerChunk" */
        './containers/WebView/Covid-19'),
    loading: Loading,
    modules: ['CovidViewContainerChunk']
});
//CoVID 19 landing page
const CovidLandingPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "CovidLandingPageContainerChunk" */
        './containers/WebView/Covid-19/CovidLandingPage'),
    loading: Loading,
    modules: ['CovidLandingPageContainerChunk']
});
//Covid 19 FAQ answer page
const FAQAnswerContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "FAQAnswerContainerChunk" */
        './containers/WebView/Covid-19/FAQAnswer'),
    loading: Loading,
    modules: ['FAQAnswerContainerChunk']
});
//Covid 19 category wise FAQ page
const CategoryFAQContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "CategoryFAQContainerChunk" */
        './containers/WebView/Covid-19/CategoryFAQ'),
    loading: Loading,
    modules: ['CategoryFAQContainerChunk']
});

const KickCounterFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "KickCounterFAQWebViewChunk" */
        './containers/WebView/KickCounter'),
    loading: Loading,
    modules: ['KickCounterFAQWebViewChunk']
});

const CommunityGuideline = Loadable({
    loader: () => import(
        /* webpackChunkName: "CommunityGuidelineChunk" */
        './containers/CommunityGuideline'),
    loading: Loading,
    modules: ['CommunityGuidelineChunk']
});

const CommunityGuidelineWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "CommunityGuidelineWebViewChunk" */
        './containers/CommunityGuideline/webView.js'),
    loading: Loading,
    modules: ['CommunityGuidelineWebViewChunk']
});

const PrivacyPolicy = Loadable({
    loader: () => import(
        /* webpackChunkName: "PrivacyPolicyChunk" */
        './containers/PrivacyPolicy'),
    loading: Loading,
    modules: ['PrivacyPolicyChunk']
});

const PrivacyPolicyWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "PrivacyPolicyWebViewChunk" */
        './containers/PrivacyPolicy/webView.js'),
    loading: Loading,
    modules: ['PrivacyPolicyWebViewChunk']
});

const AndroidAppDownload = Loadable({
    loader: () => import(
        /* webpackChunkName: "AndroidAppDownloadChunk" */
        './containers/AndroidAppDownload'),
    loading: Loading,
    modules: ['AndroidAppDownloadChunk']
});
// Covid-19 Good Doctor
const CovidDoctorContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "CovidDoctorContainerChunk" */
        './containers/WebView/Covid-19/CovidGoodDoctor'),
    loading: Loading,
    modules: ['CovidDoctorContainerChunk']
});

const FoodLandingPage = Loadable({
    loader: () => import(
        /* webpackChunkName: "FoodLandingPageChunk" */
        './containers/FoodAndNutrients/FoodLanding'),
    loading: Loading,
    modules: ['FoodLandingPageChunk']
});

const FoodListingPage = Loadable({
    loader: () => import(
        /* webpackChunkName: "FoodListingChunk" */
        './containers/FoodAndNutrients/Listing'),
    loading: Loading,
    modules: ['FoodListingChunk']
});

const FoodDetailPage = Loadable({
    loader: () => import(
        /* webpackChunkName: "FoodDetailChunk" */
        './containers/FoodAndNutrients/DetailContainer'),
    loading: Loading,
    modules: ['FoodDetailChunk']
});

const RecipeDetailPage = Loadable({
    loader: () => import(
        /* webpackChunkName: "RecipeDetailChunk" */
        './containers/Recipes/RecipeDetail'),
    loading: Loading,
    modules: ['RecipeDetailChunk']
});

const RecipesListingContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "RecipesListingChunk" */
        './containers/Recipes/RecipesListing'),
    loading: Loading,
    modules: ['RecipesListingChunk']
});

const HealingModeFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "HealingModeFAQWebViewChunk" */
        './containers/WebView/HealingModeFAQ'),
    loading: Loading,
    modules: ['HealingModeFAQWebViewChunk']
});

const ExpertQnaWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "ExpertQnaWebViewChunk" */
        './containers/WebView/ExpertQna'),
    loading: Loading,
    modules: ['ExpertQnaWebViewChunk']
});

const FoodAndNutrientsFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "FoodAndNutrientsFAQWebViewChunk" */
        './containers/WebView/FoodAndNutrientsFAQ'),
    loading: Loading,
    modules: ['FoodAndNutrientsFAQWebViewChunk']
});

const ProductFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "ProductFAQWebViewChunk" */
        './containers/WebView/ProductFAQ'),
    loading: Loading,
    modules: ['ProductFAQWebViewChunk']
});

const BabySizeFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "BabySizeFAQWebViewChunk" */
        './containers/WebView/BabySizeFAQ'),
    loading: Loading,
    modules: ['BabySizeFAQWebViewChunk']
});

const ActivityFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "ActivityFAQWebViewChunk" */
        './containers/WebView/ActivityFAQ'),
    loading: Loading,
    modules: ['ActivityFAQWebViewChunk']
});

const MedicineFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "MedicineFAQWebViewChunk" */
        './containers/WebView/MedicineFAQ'),
    loading: Loading,
    modules: ['MedicineFAQWebViewChunk']
});

const CheckListFAQWebView = Loadable({
    loader: () => import(
        /* webpackChunkName: "CheckListFAQWebViewChunk" */
        './containers/WebView/CheckListFAQ'),
    loading: Loading,
    modules: ['CheckListFAQWebViewChunk']
});

const DeleteAccountGuideline = Loadable({
    loader: () => import(
        /* webpackChunkName: "DeleteAccountGuidelineChunk" */
        './containers/WebView/DeleteAccountGuideline'),
    loading: Loading,
    modules: ['DeleteAccountGuidelineChunk']
})

const RecipesCategoryContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "RecipesCategoryChunk" */
        './containers/Recipes/RecipesCategory'),
    loading: Loading,
    modules: ['RecipesCategoryChunk']
});

const RecipesLandingPage = Loadable({
    loader: () => import(
        /* webpackChunkName: "RecipesLandingChunk" */
        './containers/Recipes/RecipesLanding'),
    loading: Loading,
    modules: ['RecipesLandingChunk']
});

//About Kid Tracker
const AboutBabyTrackerContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "AboutBabyTrackerContainerChunk" */
        './containers/WebView/Tracker/AboutBabyTracker'),
    loading: Loading,
    modules: ['AboutBabyTrackerContainerChunk']
})

//Sitemap
const SitemapHomeContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "SitemapHomeContainerChunk" */
        './containers/Sitemap'),
    loading: Loading,
    modules: ['SitemapHomeContainerChunk']
})

//Sitemap
const ContentHubContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "ContentHubContainerChunk" */
        './containers/WebView/ContentHub/HomePage'),
    loading: Loading,
    modules: ['ContentHubContainerChunk']
})

const ContentHubDetailPageContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "ContentHubDetailPageContainerChunk" */
        './containers/WebView/ContentHub/DetailPage'),
    loading: Loading,
    modules: ['ContentHubDetailPageContainerChunk']
})

const BYTermsAndConditionsContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "ContentHubDetailPageContainerChunk" */
        './containers/BYTermsAndConditions'),
    loading: Loading,
    modules: ['BYTermsAndConditionsContainerChunk']
})

const UserSettingsContainer = Loadable({
    loader: () => import(
        /* webpackChunkName: "UserSettingsContainerChunk" */
        './containers/UserSettings'),
    loading: Loading,
    modules: ['UserSettingsContainerChunk']
})

const App = (props) => {
    const location = useLocation();
    
    const [landingUrl] = useState(location.pathname);
    
    const [tokenUserBeyeu] = useState(() => {
       return new URLSearchParams(location.search).get("tokenUserBeyeu")
    });

    useEffect(() => {
        if(tokenUserBeyeu && !props.userReducer.token){
            const authType = 'email'
            
            const {email, password} = generateParamsLogin({tokenUser: tokenUserBeyeu})
            props.login(email, password, "sign-up", authType).then((res) => {
                if(res && res.status) {
                    if(res.response && res.response.is_new){
                        gtmSend('signup_success', 'Sign Up', 'Sign Up with: Email', '--', 0, {action: 'Success'});
                    }else{
                        gtmSend('login_success', 'Login', 'Success', '--', 0, {action: 'Success'});
                    }
                    gtmSend('click login', 'login', 'click', authType);
                    props.setUserLoggedIn(res)
                        .then(() => {
                        window.history.pushState('', document.title, `${location.pathname}?lng=vn`)
                    });    
                } else if(res) {
                    console.log("Fail--",res.message);
                    gtmSend('login_signup_fail', 'Login/ Sign Up', 'Fail', res.message, 0, {action: 'Fail'});
                }
            })
        } else {
            window.history.pushState('', document.title, `${location.pathname}?lng=vn`)
        }
    },[tokenUserBeyeu])

    /** Fix WBY2-92 - [Web] Unload other user profile **/
    useEffect(() => {
        if (isBeYeuTemplate && props.userReducer.slug) {
            props.getProfileFetch(props.userReducer.slug)
                .then((response) => {
                if(response.profile && response.profile.block_user && response.profile.block_user.anonymous_status === 1){
                    window.location.href = '/feed?lng=vn'
                }else{
                    const user = response.profile;
                    const userData = {
                        date_of_delivery: user.date_of_delivery,
                        gender: user.gender,
                        is_pregnant: user.is_pregnant,
                        kidsInfo: user.advance_kids_info ? user.advance_kids_info.all : props.userReducer.kidsInfo,
                        num_kids: user.advance_kids_info ? user.advance_kids_info.all.length : props.userReducer.num_kids,
                        tracker_type: user.tracker_type,
                        image: user.image,
                    };
                    props.completeAuthorProfileUpdate(userData);
                }
            });
        }
    }, [props.userReducer.slug])

    return (
        <Switch>
            <Route 
                path='/' 
                render={(n) => <LandingPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            { isBeYeuTemplate &&
              <Route 
                  path='/terms-and-conditions' 
                  render={(n) => <BYTermsAndConditionsContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
              />
            }
              <Route 
                path='/feed' 
                render={(n) => <HomePageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/q/:slug/:id' 
                render={(n) => <ViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/feed/hash/:slug' 
                render={(n) => <PageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/feed/:type' 
                render={(n) => <PageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/booth' 
                render={(n) => <BoothContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/booth/hash/:hash' 
                render={(n) => <BoothHashtagContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/booth/:id' 
                render={(n) => <MemoryViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/topics' 
                render={(n) => <TopicListContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/topic/:slug' 
                render={(n) => <TopicViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/user/edit/:slug' 
                render={(n) => <AuthorEditContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/user/settings' 
                render={(n) => <UserSettingsContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/user/:slug' 
                render={(n) => <AuthorViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />                
            <Route 
                path='/search' 
                render={(n) => <SearchViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/contact' 
                render={(n) => <StaticPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/mob/privacy' 
                render={(n) => <PrivacyPolicyWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/privacy' 
                render={(n) => <PrivacyPolicy {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/tracker/:type?' 
                render={(n) => <TrackerPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/tracker/pregnancy/:week' 
                render={(n) => <TrackerPregnancyViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/tracker/baby/:month' 
                render={(n) => <TrackerBabyViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/login' 
                render={(n) => <LoginPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            {isBeYeuTemplate && <Route 
                path='/login-internal' 
                render={(n) => <LoginInternalPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />}
            <Route
                path='/onboarding'
                render={(n) => <OnboardingPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />}
            />
            <Route
                path='/contests' 
                render={(n) => <ContestPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />
            <Route 
                path='/contest/:slug/:id' 
                render={(n) => <ContestViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/tap/poll/:country?/:slug/:id' 
                render={(n) => <TapPollViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/reset/password/:token' 
                render={(n) => <PasswordResetContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/unanswered' 
                render={(n) => <UnansweredContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            {!isBeYeuTemplate && <Route 
                path='/app/download' 
                render={(n) => <AppDownloadContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />}
            <Route 
                path='/tnc/rewards' 
                render={(n) => <TnCRewardsContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/unsubscribe' 
                render={(n) => <UnsubscribeContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/invite' 
                render={(n) => <HomePageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/calc' 
                render={(n) => <Calculator {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />  
            {/*<Route path='/404' component={Template404Container} /> */}
            <Route 
                path='/videos/:id' 
                render={(n) => <VideoContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/rewards' 
                render={(n) => <RewardPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />  
            <Route 
                path='/reward/:id' 
                render={(n) => <RewardViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/kickcount/faq' 
                render={(n) => <KickCounterFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/healing/faq/web-view' 
                render={(n) => <HealingModeFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/expert_qna/faq/web-view'
                render={(n) => <ExpertQnaWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/food/faq/web-view'
                render={(n) => <FoodAndNutrientsFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/activity/faq/web-view'
                render={(n) => <ActivityFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/product/faq/web-view'
                render={(n) => <ProductFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/baby-size/faq/web-view'
                render={(n) => <BabySizeFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/medicine/faq/web-view'
                render={(n) => <MedicineFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route    
                path='/checklist/faq/web-view'
                render={(n) => <CheckListFAQWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route
                path='/delete-account-guideline/web-view'
                render={(n) => <DeleteAccountGuideline {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/community-guideline' 
                render={(n) => <CommunityGuideline {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/community-guideline/web-view' 
                render={(n) => <CommunityGuidelineWebView {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/android-app-download' 
                render={(n) => <AndroidAppDownload {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/recipes' 
                render={(n) => <RecipesLandingPage {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />
            <Route 
                path='/recipe/:slug/:id' 
                render={(n) => <RecipeDetailPage {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />
            <Route 
                path='/recipes/:type/:slug/:id' 
                render={(n) => <RecipesListingContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />
            <Route 
                path='/recipes/:type' 
                render={(n) => <RecipesCategoryContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact 
            />
            <Route 
                path='/food' 
                render={(n) => <FoodLandingPage {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/food/category/:slug/:id' 
                render={(n) => <FoodListingPage {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/food/:slug/:id' 
                render={(n) => <FoodDetailPage {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/covid-19/app-download/:country' 
                render={(n) => <CovidLandingPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/:country/covid-19/category/:type' 
                render={(n) => <CategoryFAQContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
            <Route 
                path='/:country/covid-19/web-view' 
                render={(n) => <CovidViewContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            /> 
            <Route 
                path='/:country/faq/:id' 
                render={(n) => <FAQAnswerContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/:country/covid-19-good-doctor/web-view' 
                render={(n) => <CovidDoctorContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            /> 
            <Route 
                path='/landingPage' 
                render={(n) => <LandingPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/tracker-tools' 
                render={(n) => <Template404Container {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/tracker-tools/:slug' 
                render={(n) => <Template404Container {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/about-baby-tracker/web-view' 
                render={(n) => <AboutBabyTrackerContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/sitemap' 
                render={(n) => <SitemapHomeContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/sitemap/:slug' 
                render={(n) => <SitemapHomeContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/sitemap/:slug/:year' 
                render={(n) => <SitemapHomeContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/sitemap/:slug/:year/:week' 
                render={(n) => <SitemapHomeContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
              <Route 
                path='/content-hub/:slug/:slug1/web-view' 
                render={(n) => <ContentHubContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
              <Route 
                path='/:slug/:slug1/:slug2/web-view' 
                render={(n) => <ContentHubDetailPageContainer {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
                exact
            />
            <Route 
                path='/:slug' 
                render={(n) => <Template404Container {...n} landingUrl={landingUrl} serverReqQuery={props.serverReqQuery || {}} serverReqCookie={props.serverReqCookie || ''} />} 
            />
        </Switch>
    );
}

const mapStateToProps = (state) => state;

//map dispatch
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        login,
        setUserLoggedIn,
        authorProfileAndPostsFetch,
        completeAuthorProfileUpdate,
        getProfileFetch
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App)