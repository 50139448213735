import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { countries, defaultLang } from '../../../config'

i18n
.use(LanguageDetector)
.use(Backend)
.init({
    whitelist: countries,
    fallbackLng: defaultLang,
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    // react i18next special options (optional)
    // react: {
    //     wait: false,
    //     bindI18n: 'languageChanged loaded',
    //     bindStore: 'added removed',
    //     nsMode: 'default'
    // },
    backend: {
        loadPath: '{{lng}}/{{ns}}',
        parse: (data) => data,
        ajax: loadLocales
    }
});

function loadLocales(url, options, callback) {    
    try {
        const localeData = require('../locales/' + url + '.json');
        callback(localeData, {status: '200'});
    } catch (e) {
        console.log(e);
        callback(null, {status: '404'});
    }
}

export default i18n;