/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
import update from 'react-addons-update';
import {findTopicIndexById, findIndexById} from '../helpers';

function IS() {
    return {
        currentFilter: 'home_feed',
        currentFilterId: 0,
        currentFeed: 'newsfeed',
        feedList: [],
        page: 1,
        filter: null,
        topic: null,
        author: null,
        ifServerFilled: false,
        accessedFrom: '',
        ifInfiniteLoading: false,
        countryId: 0,
        filtersList: [],
        widget: {},
        has_next: false
    };
}

const INITIAL_STATE = IS();

export const feedReducer = (state = INITIAL_STATE, action) => {
    let index, subIndex;
    switch (action.type) {
        case 'START_FEED_FETCH':
            return {
                ...state,
                ifInfiniteLoading: true
            }       
        case 'COMPLETE_FEED_FETCH':
            return {
                ...state,
                currentFilter: action.payload.currentFilter,
                currentFilterId: action.payload.currentFilterId,
                currentFeed: action.payload.currentFeed,
                page: action.payload.page,
                // filter: action.payload.filter,
                ifServerFilled: action.payload.ifServerFilled,
                accessedFrom: action.payload.accessedFrom,
                ifInfiniteLoading: false,
                feedList: [...(action.payload.shouldReset ? action.payload.response.feed : [...state.feedList, ...action.payload.response.feed])],
                topic: action.payload.response.group ? action.payload.response.group : state.group,
                author: action.payload.response.profile ? action.payload.response.profile : state.author,
                countryId: action.payload.countryId ? action.payload.countryId : state.countryId,
                widget: action.payload.response.widget && action.payload.response.widget.widget ? action.payload.response.widget.widget : {},
                has_next: action.payload.response.has_next ? action.payload.response.has_next : false
            }
        case 'START_CONTENTHUB_FEED_FETCH':
            return {
                ...state,
                ifInfiniteLoading: true
            }       
        case 'COMPLETE_CONTENTHUB_FEED_FETCH':
            return {
                ...state,
                feedList: [...(action.payload.shouldReset ? action.payload : [...state.feedList, ...action.payload])],
                ifInfiniteLoading: false,
                page: action.payload.page,
            }
        case 'START_FEED_QUESTION_EDIT':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_QUESTION_EDIT':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        $set: action.payload.response.feed[0]
                    }
                })
            }
        case 'START_FEED_REPLY_EDIT':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_REPLY_EDIT':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            [subIndex]: {$set: action.payload.response.replies[0]}
                        }
                    }
                })
            }
        case 'START_FEED_QUESTION_REPLY':
            return {
                ...state,
                 ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_QUESTION_REPLY':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        // more_reply_count: {$set: state.feedList[index].more_reply_count + 1},
                        replies: {$set: [...action.payload.response.replies, ...state.feedList[index].replies]}
                    }
                })
            }
        case 'START_FEED_POLL_VOTE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_POLL_VOTE':
            index = findTopicIndexById(action.payload.postId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        total_votes: {$set: action.payload.response.total_votes},
                        votes: {$set: action.payload.response.votes},
                        already_voted: {$set: 1},
                    }
                })
            }
        case 'COMPLETE_FEED_QUESTION_LIKE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                feedList: update(state.feedList, {
                    [index]: {
                        likes: {$set: {count: action.payload.feedLikeCount + 1, liked: action.payload.liked}}
                    }
                })
            }
        case 'COMPLETE_FEED_QUESTION_UNLIKE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                feedList: update(state.feedList, {
                    [index]: {
                        likes: {$set: {count: action.payload.feedLikeCount - 1, liked: action.payload.liked}}
                    }
                })
            }
        case 'COMPLETE_FEED_REPLY_LIKE':
            index = findTopicIndexById(action.payload.postId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            [subIndex]: {
                                likes: {$set: {...action.payload.likes}},
                                ...(action.payload.dislikes ? {dislikes: {$set: {...action.payload.dislikes}}} : {})
                            }
                        }                        
                    }
                })  
            }
        case 'COMPLETE_FEED_REPLY_DISLIKE':
            index = findTopicIndexById(action.payload.postId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            [subIndex]: {
                                likes: {$set: {...action.payload.likes}},
                                ...(action.payload.dislikes ? {dislikes: {$set: {...action.payload.dislikes}}} : {})
                            }
                        }                        
                    }
                })  
            }
        case 'START_FEED_QUESTION_HIDE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_QUESTION_HIDE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return {...state, ifInfiniteLoading: false};            
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: [...state.feedList.slice(0, index), ...state.feedList.slice(index + 1)]
            }
        case 'START_FEED_QUESTION_DELETE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_QUESTION_DELETE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return {...state, ifInfiniteLoading: false};
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: [...state.feedList.slice(0, index), ...state.feedList.slice(index + 1)]
            }
        case 'START_FEED_REPLY_DELETE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_REPLY_DELETE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            $set: [...state.feedList[index].replies.slice(0, subIndex), ...state.feedList[index].replies.slice(subIndex + 1)]
                        }                        
                    }
                })
            }
        case 'START_FEED_REPLY_COMMENT_DELETE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_REPLY_COMMENT_DELETE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;            
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            [subIndex]: {
                                // more_comment_count: {$set: action.payload.response.more_comment_count},
                                comments: {$set: []}
                            }
                        }                        
                    }
                })
            }
        case 'START_FEED_REPLY_COMMENT_SAVE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_FEED_REPLY_COMMENT_SAVE':
            index = findTopicIndexById(action.payload.postId, state.feedList);
            if(index < 0) return state;
            subIndex = findIndexById(action.payload.replyId, state.feedList[index].replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        replies: {
                            [subIndex]: {
                                more_comment_count: {$set: action.payload.response.more_comment_count},
                                comments: {$set: action.payload.response.comments}
                            }
                        }                        
                    }
                })
            }
        case 'COMPLETE_FEED_ACTIONS_UPDATE':
            index = findTopicIndexById(action.payload.questionId, state.feedList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                feedList: update(state.feedList, {
                    [index]: {
                        actions: {$set: action.payload.response.actions}
                    }
                })
            }
        case 'COMPLETE_FILTERS_FETCH':
            return {
                ...state,
                filtersList: action.payload
            }
        case 'RESET_FEED_LOADER':
            return {
                ...state,
                ifInfiniteLoading: false
            }                    
        default:
            return state
    }
};