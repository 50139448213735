import { isBeYeuTemplate } from './index'

const msExtension = {
    msApiBase : 'api/ms/',
    apiBase : 'api/v2/',
    apiBaseV3 : 'api/v3/',
}

const apiBaseCommunity = {
    'production': 'https://parenttown.com/',
    'staging': 'https://staging.parenttown.com/',
    'development': 'https://staging.parenttown.com/'
}

const apiBaseTap = {
    'production': isBeYeuTemplate ? 'https://api.beyeu.com/api/v1/' : 'https://api.theasianparent.com/api/v1/',
    'staging': isBeYeuTemplate ? 'https://dev-api.beyeu.com/api/v1/' : 'https://dev-api.theasianparent.com/api/v1/',
    'development': isBeYeuTemplate ? 'https://dev-api.beyeu.com/api/v1/' : 'https://dev-api.theasianparent.com/api/v1/'
}


export const getCommunityAPIBase = (env = 'staging', extension = 'apiBase') => {
    return `${apiBaseCommunity[['production', 'staging', 'development'].indexOf(env) !== -1 ? env : 'staging']}${msExtension[extension]}`;
}

export const getTapAPIBase = (env = 'staging') => {
    return apiBaseTap[['production', 'staging', 'development'].indexOf(env) !== -1 ? env : 'staging']
}
