/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        page: 1,
        ifServerFilled: false,
        answer: null,
        shareUrl: null
    };
}

const INITIAL_STATE = IS();

export const covidAnswerReducer = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case 'START_COVID_ANSWER_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_COVID_ANSWER_FETCH':
            return {
                ...state,
                isLoading: false,
                answer: action.payload.response.qna,
                shareUrl: action.payload.response.share_url
            }
        default:
            return state
    }
};
