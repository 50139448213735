/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        page: 1,
        ifServerFilled: false,
        list: [],
        rewardId: null,
        details: null,
        moreList: [],
        rewardsData: {}
    };
}

const INITIAL_STATE = IS();

export const rewardReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_REWARDS_LIST_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_REWARDS_LIST_FETCH':
            return {
                ...state,
                isLoading: false,
                page: action.payload.page,
                ifServerFilled: action.payload.ifServerFilled,
                list: [...(action.payload.shouldReset ? action.payload.response.list : (action.payload.response.list ? [...state.list, ...action.payload.response.list] : [...state.list]))],
                rewardsData: {
                    ...state.rewardsData,
                    ...action.payload.response.rewardsData
                }
            }
        case 'START_REWARD_DETAILS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_REWARD_DETAILS_FETCH':
            return {
                ...state,
                isLoading: false,
                rewardId: action.payload.response.reward_detail.id,
                details: action.payload.response.reward_detail
            }
        // case 'COMPLETE_CONTEST_MORE_LIST_FETCH':
        //     return {
        //         ...state,
        //         isLoading: false,
        //         moreList: [...action.payload.response.contests]
        //     }
        default:
            return state
    }
};
