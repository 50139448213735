/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        page: 1,
        error: null,
        data: {}, //landing page data
        listing: {}, // listing page data,
        detail: {} //detail page data
    };
}

const INITIAL_STATE = IS();

export const foodReducer = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case 'START_FOOD_LANDING_DATA_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_FOOD_LANDING_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case 'START_FOOD_LISTING_DATA_FETCH':
            return {
                ...state,
                isLoading: true,
                listing: action.payload
            }
        case 'COMPLETE_FOOD_LISTING_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                listing: {
                    foods: action.payload.foods,
                    category: action.payload.category,
                    meta: action.payload.meta
                },
                page: action.payload.page 
            }
        case 'START_FOOD_DETAIL_DATA_FETCH':
            return {
                ...state,
                isLoading: true,
                detail: action.payload
            }
        case 'COMPLETE_FOOD_DETAIL_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                detail: action.payload
            }
        case 'RESET_FOOD_LOADER':
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
};
