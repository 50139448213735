import React from 'react';
import { hydrate } from 'react-dom';
import {Provider} from 'react-redux';
import { Router } from 'react-router-dom';
import history from './other/history';//important!! -- dont use browser router
import configureStore from "./store";
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import Loadable from 'react-loadable';
import bugsnag from 'bugsnag-js'
import bugsnagReact from 'bugsnag-react'
import { I18nextProvider } from 'react-i18next';
import i18nInstance from './other/localization/i18n';
import { getLCP, getFCP, getCLS, getTTFB } from 'web-vitals'; //1.13Kb
import { mobileCheck } from '@tickled-media/web-components.tm-helpers'
import { gtmSend } from './helpers';

// Let the reducers handle initial state
const store = configureStore(window.__REDUX_STATE__ || null);

if(mobileCheck() && window.__REDUX_STATE__ && ([1, 3, 5, 7, 9].indexOf(window.__REDUX_STATE__.appReducer.randomUserNumber) !== -1 || process.env.REACT_APP_ENV !== 'production')) {
    const sendToGoogleAnalytics = (props) => {
        if(process.env.REACT_APP_ENV !== 'production') {
            console.log(props);
        }

        let label = ``;
        if(props.entries) {
            for(const i in props.entries) {
                if(props.name === 'LCP' && props.entries[i].element) {
                    if(props.entries[i].element.className) {
                        label = `${label} | class: ${props.entries[i].element.className}`
                    }
                    else if(props.entries[i].element.ariaLabel) {
                        label = `${label} | aria: ${props.entries[i].element.ariaLabel}`    
                    }
                }
                if(props.name === 'CLS' && props.entries[i].sources) {
                    for(const j in props.entries[i].sources) {
                        if(props.entries[i].sources[j].node) {
                            if(props.entries[i].sources[j].node.className) {
                                label = `${label} | class: ${props.entries[i].sources[j].node.className}`
                            }
                            else if(props.entries[i].sources[j].node.ariaLabel) {
                                label = `${label} | aria: ${props.entries[i].sources[j].node.ariaLabel}`    
                            }
                        }
                    }
                }
            }
        }

        gtmSend('dynamic', `Web Vitals`, `${props.name}`, `${window.serverRouteName}${label}`, Math.round(props.name === 'CLS' ? props.delta * 1000 : props.delta));
    }
    getFCP(sendToGoogleAnalytics);
    getCLS(sendToGoogleAnalytics, process.env.REACT_APP_ENV === 'production' ? false : true);
    getLCP(sendToGoogleAnalytics, process.env.REACT_APP_ENV === 'production' ? false : true);
    getTTFB(sendToGoogleAnalytics);
}

const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_ID_CLIENT,
    appVersion: process.env.REACT_APP_JSVERSION,
    autoCaptureSessions: false
})
const ErrorBoundary = bugsnagClient.use(bugsnagReact(React))


//render or hydrate -- ssr
//let renderMethod = !!module.hot ? render : hydrate;
if (module.hot) {
    renderClient();
}
else {
    let isTriggerLoadFun = true;

    document.onreadystatechange = function () {
        if (document.readyState == "interactive") {
            if(isTriggerLoadFun) {
                renderClient();  
                isTriggerLoadFun = false;
            }
        }
    }
    
    //Backup load code if DOMContentLoaded didnt work
    window.addEventListener("load", function() {
        if(isTriggerLoadFun) {
            renderClient();  
            isTriggerLoadFun = false;
        }
    });
}

function renderClient() {
    Loadable.preloadReady().then(() => {
        hydrate(
            <I18nextProvider i18n={i18nInstance}>
                <Provider store={store}>
                    <Router history={history}>
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </Router>
                </Provider>
            </I18nextProvider>,
            document.getElementById('root')
        );
    });
}

// registerServiceWorker();
