/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
//initial state wi;l be empty after adding login
function IS() {
    return {
        token: null,
        id: null,
        url: "",
        fullname: "",
        tagline: "",
        image: "",
        location: "",
        isVerified: null,
        staff: null,
        stepRegister: 0,
        lang_code: '',
        followers: [],
        following: [],
        userSettings: {},
        dataSharingInfo: {},
        isLoading: false,
        isDataSharingEnabled: false,
        // id: 3,
        // url: "https://staging.parenttown.com/user/vishal-vyas",
        // fullname: "User",
        // tagline: "Logged In",
        // image: generateAssetCdnUrl("groups-tap", "profile_15222430663072.jpg"),
        // location: "India",
        // is_verified: 1,
        // staff: 1
    };
}

const INITIAL_STATE = IS();

export const userReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_USER_AUTHENTICATION':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_USER_AUTHENTICATION':
            return {
                ...state,
                ...action.payload.response,
                isLoading: false,
                isVerified: action.payload.response.is_verified,
                stepRegister: action.payload.response.step_register,
            }
        case 'COMPLETE_USER_ONBOARDING':
            return {
                ...state,
                tracker_info: {...state.tracker_info, ...action.payload},
                isLoading: false,
                stepRegister: 0,
                step_register: 0
            }
        case 'START_USER_FOLLOWERS_FETCH':
            return {
                ...state,
                followers: action.payload,
                isLoading: true
            }
        case 'COMPLETE_USER_FOLLOWERS_FETCH':
            return {
                ...state,
                isLoading: false,
                followers: [...(action.payload.shouldReset ? action.payload.response.users : [...state.followers, ...action.payload.response.users])]
            }
        case 'START_USER_FOLLOWING_FETCH':
            return {
                ...state,
                following: action.payload,
                isLoading: true
            }
        case 'COMPLETE_USER_FOLLOWING_FETCH':
            return {
                ...state,
                isLoading: false,
                following: [...(action.payload.shouldReset ? action.payload.response.users : [...state.following, ...action.payload.response.users])]
            }
        case 'RESET_USER_LOADER':
            return {
                ...state,
                isLoading: false
            }
        case 'START_USER_PROFILE_UPDATE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_USER_PROFILE_UPDATE':
            return {
                ...state,
                ...action.payload,
                isLoading: false
            }
        case 'START_USER_SETTINGS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_USER_SETTINGS_FETCH':
            return {
                ...state,
                userSettings: action.payload? action.payload : {},
                isLoading: false
            }
        case 'START_USER_SETTINGS_UPDATE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_USER_SETTINGS_UPDATE':
            return {
                ...state,
                userSettings: {},
                isLoading: false
            }
        case 'UPDATE_DATA_SHARING_STATUS':
            return {
                ...state,
                isDataSharingEnabled: Boolean(action.payload && action.payload.data_sharing && action.payload.data_sharing.is_enabled)
            }
        default:
            return state
    }
};
