/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

import {findIndexById} from '../helpers';

function IS() {
    return {
        isLoading : true,
        userList : []
    };
}

const INITIAL_STATE = IS();

export const blockUserList = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'START_BLOCK_USER_LIST_FETCH':
            return {
                ...state,
                isLoading: false
            }       
        case 'COMPLETE_BLOCK_USER_LIST_FETCH':
            return {
                ...state,
                userList : action.payload.users
            }
        case 'START_BLOCK_LIST_REMOVE_USER':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_BLOCK_LIST_REMOVE_USER':
            const index = findIndexById(action.payload.userId, state.userList);
            if(index < 0) return {...state, ifInfiniteLoading: false};            
            return {
                ...state,
                isLoading: false,
                userList: [...state.userList.slice(0, index), ...state.userList.slice(index + 1)]
            }
          
        default:
            return state
    }
};