import { generateAssetCdnUrl } from '@tickled-media/web-components.tm-helpers';
// import ADS from './ads';
//import REGION from './region';
import API from './api';

const socialLinks = {
    sg: {
        fb: "https://www.facebook.com/theasianparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent/"
    },
    ph: {
        fb: "https://www.facebook.com/filipinoparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent_ph/"
    },
    id: {
        fb: "https://www.facebook.com/indonesianparent",
        //twitter: "https://twitter.com/AsianParentID",
        youtube: "https://www.youtube.com/channel/UC9KUiZz6CDgADq3bRCdGh-w",
        insta: "https://www.instagram.com/theasianparent_id/"
    },
    my: {
        fb: "https://www.facebook.com/theAsianparentMY",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent_my/"
    },
    vn: {
        fb: "https://www.facebook.com/beyeuapp",
        youtube: "https://www.youtube.com/channel/UCN8k6SDJ7zkOSfe233iAfAQ",
        tiktok: "https://www.tiktok.com/@beyeuapp"
    },
    th: {
        fb: "https://www.facebook.com/theAsianparentTH",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/channel/UCOrctFJ2TgoVIWZbjz2qgPQ",
        insta: "https://www.instagram.com/theasianparent_th/"
    },
    in: {
        fb: "https://www.facebook.com/indusparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/channel/UC6ahM0GIwQbZJSUWRnjMsGA/feed",
    },
    jp: {
    },
    tw: {
        fb: "https://www.facebook.com/theAsianparentTW"
    },
    hk: {
        fb: "https://www.facebook.com/theasianparentHK"
    } 
};

export const isBeYeuTemplate = process.env.REACT_APP_SITE_TYPE_TEMPLATE === 'beyeu'

export const template = process.env.REACT_APP_SITE_TYPE_TEMPLATE || ''

const regionObj = () => {
    if (isBeYeuTemplate) {
        return {
            region: "asia",
            keyword: "Bé Yêu",
            siteName: "Bé Yêu",
            plural: "Asian",
            defaultCountry: 'vn',
            defaultLang: 'vn',
            s3Prefix: generateAssetCdnUrl(),
            logoImage: "/prod-beyeu-assets/community/Community_logo.png",
            favicon: generateAssetCdnUrl("prod-beyeu-assets/favicon", "favicon.ico"),
            footerLogoImage: "/prod-beyeu-assets/community/Community_logo.png",
            staticAd1: generateAssetCdnUrl("prod-beyeu-assets/community", "TAPApps_launch_1b_300x250.jpg"),
            staticAd2: generateAssetCdnUrl("prod-beyeu-assets/community", "TAPApps_launch_2b_300x250.jpg"),
            staticAd3: generateAssetCdnUrl("prod-beyeu-assets/community", "TAPApps_launch_3b_300x250.jpg"),
            ogImage: generateAssetCdnUrl("prod-beyeu-assets/favicon", "android-chrome-512x512.png"),
            appDownload: 'https://r5j52.app.goo.gl/beyeu'
        }
    }
    return {
        region: "asia",
        keyword: "theAsianparent",
        siteName: "theAsianparent",
        plural: "Asian",
        defaultCountry: 'sg',
        defaultLang: 'en',
        s3Prefix: generateAssetCdnUrl(),
        logoImage: "/parenttown-prod/community/Community_logo.svg",
        favicon: generateAssetCdnUrl("tap-assets", "favicon.ico"),
        footerLogoImage: "/parenttown-prod/community/TAP+logo_white_text-27.png",
        staticAd1: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_1b_300x250.jpg"),
        staticAd2: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_2b_300x250.jpg"),
        staticAd3: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_3b_300x250.jpg"),
        ogImage: generateAssetCdnUrl("parenttown-prod/assets", "tap_c_logo_512x512.png")
    };
}

const socialIds = {
    fbAppId: {
        development: isBeYeuTemplate ? '260339341006310' : '1677574999137048',
        staging: isBeYeuTemplate ? '260339341006310' : '1677574999137048',
        production: isBeYeuTemplate ? '260339341006310' : '1038004076364647',
    },
    googleClientId: {
        development: isBeYeuTemplate ? '43045965778-h4tal0l0p541vsonqgso21vuap9eo745.apps.googleusercontent.com' : '578851203267-hjmi0d5drv70gpd14upds2740l0r0hut.apps.googleusercontent.com',
        staging: isBeYeuTemplate ? '43045965778-h4tal0l0p541vsonqgso21vuap9eo745.apps.googleusercontent.com' : '856646768262-6mpc7b0l56r3rbrhvglo52t9jit6lb1b.apps.googleusercontent.com',
        production: isBeYeuTemplate ? '43045965778-h4tal0l0p541vsonqgso21vuap9eo745.apps.googleusercontent.com' : '1004252111741-p7gbg8eb17fh9ua589tlk02dobjuc2fd.apps.googleusercontent.com',
    },
    captchaKey: {
        development: isBeYeuTemplate ? '6LesU6YgAAAAACpmwxC7oJHgpujkBEzxyqbEP48y' : '6Le14-8UAAAAAGqvDBZef02DZkwIIcmnn2Sqy0Pg',
        staging: isBeYeuTemplate ? '6LesU6YgAAAAACpmwxC7oJHgpujkBEzxyqbEP48y' : '6Le14-8UAAAAAGqvDBZef02DZkwIIcmnn2Sqy0Pg',
        production: isBeYeuTemplate ? '6LesU6YgAAAAACpmwxC7oJHgpujkBEzxyqbEP48y' : '6Le14-8UAAAAAGqvDBZef02DZkwIIcmnn2Sqy0Pg',
    }
}

export const siteCommunityUrl = {
    development: 'https://localhost:3000/',
    staging: isBeYeuTemplate ? 'https://dev-parent-community.beyeu.com/' : 'https://dev-community.theasianparent.com/',
    production: isBeYeuTemplate ? 'https://community.beyeu.com/' : 'https://community.theasianparent.com/'
}

export const siteCommunityShortUrl = {
    development: isBeYeuTemplate ? 'https://r5j52.app.goo.gl/beyeu' : 'https://theasianparent.page.link/vKHcK5GZgiCpHkeu5/',
    staging: isBeYeuTemplate ? 'https://r5j52.app.goo.gl/beyeu' : 'https://theasianparent.page.link/vKHcK5GZgiCpHkeu5/',
    production: isBeYeuTemplate ? 'https://r5j52.app.goo.gl/beyeu' : 'https://theasianparent.page.link/vKHcK5GZgiCpHkeu5/'
}

export const siteNewsUrl = {
    development: 'http://localhost:3000/',
    staging: isBeYeuTemplate ? 'https://dev-parent.beyeu.com/' : 'https://dev-parent.theasianparent.com/',
    production: isBeYeuTemplate ? 'https://beyeu.com/' : 'https://sg.theasianparent.com/'
}

export const domain = {
    development: 'localhost',
    staging: isBeYeuTemplate ? '.beyeu.com' : '.theasianparent.com',
    production: isBeYeuTemplate ? '.beyeu.com' : '.theasianparent.com',
}

export const captchaKey = isBeYeuTemplate ? '6LesU6YgAAAAACpmwxC7oJHgpujkBEzxyqbEP48y' : '6Le14-8UAAAAAGqvDBZef02DZkwIIcmnn2Sqy0Pg'

const CONFIG = {
    development: {
        siteUrl: siteCommunityUrl['development'],
        siteShortUrl: siteCommunityShortUrl['development'],
        domain: domain['development'],
        apiBase: 'https://staging.parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'STG',
        // fbAppId: '194760671166813',
        fbAppId: socialIds['fbAppId']['development'],
        googleClientId: socialIds['googleClientId']['development'],
        redisUrl: 'gp2-redis-staging.theasianparent.com',
        region: regionObj(),
        bugsnagKey: '',
        captchaKey: socialIds['captchaKey']['development'],
    },
    staging: {
        siteUrl: siteCommunityUrl['staging'],
        siteShortUrl: siteCommunityShortUrl['staging'],
        domain: domain['staging'],
        apiBase: 'https://staging.parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'STG',
        // fbAppId: '2087861241473027',
        fbAppId: socialIds['fbAppId']['staging'],
        googleClientId: socialIds['googleClientId']['staging'],
        redisUrl: 'gp2-redis-staging.theasianparent.com',
        region: regionObj(),
        bugsnagKey: '',
        captchaKey: socialIds['captchaKey']['staging'],
    },
    production: {
        domain: domain['production'],
        siteUrl: siteCommunityUrl['production'],
        siteShortUrl: siteCommunityShortUrl['production'],
        apiBase: 'https://parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'PRD',
        fbAppId: socialIds['fbAppId']['production'],
        googleClientId: socialIds['googleClientId']['production'], //africa = 917606148957-aroc0js4acp3fa0ta0dqvruojkincvdr.apps.googleusercontent.com
        redisUrl: 'community-redis.theasianparent.com',
        region: regionObj(),
        bugsnagKey: '91edee8de684e008a939d5c04a10be2a',
        captchaKey: socialIds['captchaKey']['production'],
    }
};

const CONFIG_ENV = CONFIG[process.env.REACT_APP_ENV]

const regionData = CONFIG_ENV['region']

export const defaultLang = regionData['defaultLang']

export const defaultCountry = regionData['defaultCountry']

export const countries = isBeYeuTemplate ? ['en', 'vn'] : ['en', 'id', 'my', 'th', 'vn', 'ph']

export default CONFIG_ENV;
