/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        content : {}
    };
}

const INITIAL_STATE = IS();

export const contentHubReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'START_CONTENTHUB_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_CONTENTHUB_FETCH':
            return {
                ...state,
                isLoading: false,
                topicList: action.payload
            }
        default:
            return state
    }
};
