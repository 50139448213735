/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        error: null,
		sitemapYears: [],
        sitemapWeeks: [],
        sitemapArticles: []
    };
}

const INITIAL_STATE = IS();

export const sitemapReducer = (state = INITIAL_STATE, action) => {
	switch(action.type){
        case 'START_SITEMAP_HOME_DATA_FETCH':
            return {
                ...state,
                isLoading: true
            }
		case 'COMPLETE_SITEMAP_HOME_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                sitemapYears: action.payload
            }
        case 'START_SITEMAP_ARTICLE_BY_YEAR_DATA_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_SITEMAP_ARTICLE_BY_YEAR_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                sitemapWeeks: action.payload
            }
        case 'START_SITEMAP_ARTICLE_BY_WEEK_DATA_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_SITEMAP_ARTICLE_BY_WEEK_DATA_FETCH':
            return {
                ...state,
                isLoading: false,
                sitemapArticles: action.payload
            }
		default:
			return state
	}
};