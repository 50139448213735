/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        page: 1,
        ifServerFilled: false,
        data: {},
        moreArticleData: []
    };
}

const INITIAL_STATE = IS();

export const covidLandingReducer = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case 'START_COVID_LANDING_PAGE_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_COVID_LANDING_PAGE_FETCH':
            return {
                ...state,
                isLoading: false,
                data: action.payload.response,
            }
        case 'START_COVID_LOAD_MORE_dATA':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_COVID_LOAD_MORE_DATA':
            return {
                ...state,
                isLoading: false,
                moreArticleData: [...state.moreArticleData,...action.payload.response.articles]
            }
        default:
            return state
    }
};
