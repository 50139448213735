import i18n from 'i18next';
import { countries, defaultLang } from '../../../config'
// import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-xhr-backend';

i18n
// .use(LanguageDetector)
// .use(Backend)
.init({
    whitelist: countries,
    fallbackLng: defaultLang,
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    // react i18next special options (optional)
    // react: {
    //     wait: false,
    //     bindI18n: 'languageChanged loaded',
    //     bindStore: 'added removed',
    //     nsMode: 'default'
    // },
    // backend: {
    //     loadPath: '{{lng}}/{{ns}}',
    //     parse: (data) => data,
    //     ajax: loadLocales
    // }
});

// function loadLocales(url, options, callback, data) {    
//     try {
//         let localeData = require('../locales/' + url + '.json');
//         callback(localeData, {status: '200'});
//     } catch (e) {
//         console.log('word', e);
//         callback(null, {status: '404'});
//     }
// }

export default i18n;