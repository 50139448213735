/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        ifServerFilled: false,
        data: {},
    };
}

const INITIAL_STATE = IS();

export const covidCategoryQnAReducer = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case 'START_COVID_CATEGORY_QNA_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_COVID_CATEGORY_QNA_FETCH':
            return {
                ...state,
                isLoading: false,
                data: {...state.data, ...action.payload},
            }
        case 'START_ADD_COVID_CATEGORY_QNA_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_ADD_COVID_CATEGORY_QNA_FETCH':
            return {
                ...state,
                isLoading: false,
                data: {...state.data, 
                        qna: [...state.data.qna, ...action.payload]
                },
            }
        default:
            return state
    }
};
