/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        error: null,
        landing: {},
        category: {},
        listing: {},
		selectedRecipe: {},
		page: 1
    };
}

const INITIAL_STATE = IS();

export const recipeReducer = (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'START_RECIPE_PAGE_DATA_FETCH':
			return {
				...state,
				isLoading: true
			}
		case 'COMPLETE_RECIPE_DETAIL_DATA_FETCH':
			return {
				...state,
				isLoading: false,
				selectedRecipe: action.payload
			}
		case 'COMPLETE_RECIPES_LISTING_DATA_FETCH':
			return {
				...state,
				isLoading: false,
				listing: {
					cuisine_info : action.payload.cuisine_info,
					filters : action.payload.filters,
					meta : action.payload.meta,
					recipes : action.payload.recipes
				},
				page : action.payload.page 
			}
		case 'COMPLETE_RECIPES_CATEGORY_DATA_FETCH':
			return {
				...state,
				isLoading: false,
				category: action.payload
			}
		case 'COMPLETE_RECIPES_LANDING_DATA_FETCH':
			return {
				...state,
				isLoading: false,
				landing: action.payload
			}
		case 'COMPLETE_RECIPE_PAGE_DATA_FETCH':
			return {
				...state,
				isLoading: false
			}
		default:
			return state
	}
};