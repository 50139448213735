/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
import update from 'react-addons-update';
import {findBoothIndexById} from '../helpers';

function IS() {
    return {
        currentFeed: 'booth',
        hashtag: '',
        boothList: [],
        page: 1,
        filter: null,
        ifServerFilled: false,
        accessedFrom: '',
        ifInfiniteLoading: false
    };
}

const INITIAL_STATE = IS();

export const boothReducer = (state = INITIAL_STATE, action) => {
    let index = null;   
    switch (action.type) {
        case 'START_BOOTH_FETCH':
            return {
                ...state,
                ifInfiniteLoading: true
            }     
        case 'COMPLETE_BOOTH_FETCH':
            return {
                ...state,
                currentFeed: action.payload.currentFeed,
                hashtag: action.payload.hashtag ? action.payload.hashtag : '',
                page: action.payload.page,
                filter: action.payload.filter,
                ifServerFilled: action.payload.ifServerFilled,
                accessedFrom: action.payload.accessedFrom,
                ifInfiniteLoading: false,
                boothList: [...(action.payload.shouldReset ? action.payload.response.feed : [...state.boothList, ...action.payload.response.feed])]
            }
        case 'COMPLETE_BOOTH_LIKE':
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                boothList: update(state.boothList, {
                    [index]: {
                        likes: {$set: {count: action.payload.boothLikeCount + 1, liked: action.payload.liked}}
                    }
                })
            }
        case 'COMPLETE_BOOTH_UNLIKE':
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                boothList: update(state.boothList, {
                    [index]: {
                        likes: {$set: {count: action.payload.boothLikeCount - 1, liked: action.payload.liked}}
                    }
                })
            }
        case 'START_BOOTH_COMMENT_SAVE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_BOOTH_COMMENT_SAVE':
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                boothList: update(state.boothList, {
                    [index]: {
                        $set: {
                            ...state.boothList[index],
                            comments: [...action.payload.response.comments, ...state.boothList[index].comments]
                        }                     
                    }
                })
            }
        case 'START_BOOTH_CAPTION_EDIT':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_BOOTH_CAPTION_EDIT':
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                boothList: update(state.boothList, {
                    [index]: {
                        $set: action.payload.response.feed[0]            
                    }
                })
            }
        case 'START_BOOTH_DELETE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_BOOTH_DELETE':
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                boothList: [...state.boothList.slice(0, index), ...state.boothList.slice(index + 1)]
            }
        case 'START_BOOTH_COMMENT_DELETE':
            return {
                ...state,
                ifInfiniteLoading: true
            }
        case 'COMPLETE_BOOTH_COMMENT_DELETE':            
            index = findBoothIndexById(action.payload.boothId, state.boothList);
            if(index < 0) return state;
            return {
                ...state,
                ifInfiniteLoading: false,
                boothList: update(state.boothList, {
                    [index]: {
                        comments: {$set: []}          
                    }
                })
            }
        case 'RESET_BOOTH_LOADER':
            return {
                ...state,
                ifInfiniteLoading: false
            }           
        default:
            return state
    }
};