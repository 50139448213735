/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
// import update from 'react-addons-update';
import {findIndexById} from '../helpers';

function IS() {
    return {
        type: 'booth',
        id: null,
        isLoading: false,
        data: {},
        error: null
    };
}

const INITIAL_STATE = IS();

export const memoryReducer = (state = INITIAL_STATE, action) => {
    let index = null;    
    switch (action.type) {
        case 'START_MEMORY_FETCH':
            return {
                ...state,
                isLoading: true
            }     
        case 'COMPLETE_MEMORY_FETCH':
            return {
                ...state,
                isLoading: false,
                id: action.payload.response.booth ? action.payload.response.booth.id : null,
                data: action.payload.response
            }
        case 'COMPLETE_MEMORY_LIKE':
            return {
                ...state,
                data: {
                    ...state.data,
                    likes: {count: action.payload.memoryLikeCount + 1, liked: action.payload.liked}
                }
            }
        case 'COMPLETE_MEMORY_UNLIKE':
            return {
                ...state,
                data: {
                    ...state.data,
                    likes: {count: action.payload.memoryLikeCount - 1, liked: action.payload.liked}
                }
            }
        case 'SHOW_INVALID_MEMORY_MESSAGE':
            return {
                ...state,
                isLoading: false,
                error: action.payload.message
            }
        case 'START_MEMORY_COMMENT_SAVE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_MEMORY_COMMENT_SAVE':
            return {
                ...state,
                isLoading: false,
                error: action.payload.response ? null : action.payload.message,
                data: {
                    ...state.data, 
                    booth: {...state.data.booth , comment_count: state.data.booth.comment_count + 1},
                    comments: [...action.payload.response.comments, ...state.data.comments]
                }
            }
        case 'START_MEMORY_CAPTION_EDIT':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_MEMORY_CAPTION_EDIT':
            return {
                ...state,
                isLoading: false,
                error: action.payload.response ? null : action.payload.message,
                data: action.payload.response.feed[0]
            }
        case 'START_MEMORY_DELETE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_MEMORY_DELETE':
            return INITIAL_STATE
        case 'START_MEMORY_COMMENT_DELETE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_MEMORY_COMMENT_DELETE':
            index = findIndexById(action.payload.commentId, state.data.comments);
            if(index < 0) return state;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    more_comment_count: state.data.more_comment_count > 0 ? state.data.more_comment_count - 1 : 0,
                    booth: {...state.data.booth , comment_count: state.data.booth.comment_count - 1},
                    comments: [...state.data.comments.slice(0, index), ...state.data.comments.slice(index + 1)]
                }
            }
        case 'START_MEMORY_COMMENTS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_MEMORY_COMMENTS_FETCH':
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    more_comment_count: action.payload.response.more_comment_count,
                    comments: [...state.data.comments, ...action.payload.response.comments]
                }

            }
        case 'RESET_MEMORY_LOADER':
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
};