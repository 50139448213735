import {isObjectEmpty} from '../helpers';

function IS() {
    return {
        q: '',
        type: '',
        page: 1,
        isLoading: false,
        ifServerFilled: false,
        top: {
            topics: [],
            questions: [],
            replies: [],
            users: []
        },    
        questions: [],
        replies: [],
        users: [],
        topics: [],
        articles: []

    };
}

const INITIAL_STATE  = IS();

export const searchReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_SEARCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_SEARCH_TOP':
            const updateObjTop = updateSearchReducerTop(state, action);
            return {
                ...state,
                isLoading: false,
                q: action.payload.q,
                type: action.payload.type,
                page: 1,
                top: {
                    ...INITIAL_STATE.top,
                    ...(!isObjectEmpty(updateObjTop) && updateObjTop)
                },
                ifServerFilled: action.payload.ifServerFilled,    
                questions: [],
                replies: [],
                users: [],
                topics: [],
                articles: []                
            }
        case 'COMPLETE_SEARCH':
            const updateObj = updateSearchReducer(state, action);
            return {
                ...state,
                isLoading: false,
                q: action.payload.q,
                type: action.payload.type,
                page: action.payload.page,
                ifServerFilled: action.payload.ifServerFilled,
                ...(!isObjectEmpty(updateObj) && updateObj)
            }
        default:
            return state
    }
};

const updateSearchReducer = (state, action) => {
    const updateObj = {};
    for(const key in action.payload.response) {
        if(key == 'order') continue;
        const mappedKey = typeMapper(key);
        updateObj[mappedKey] = action.payload.shouldReset ? action.payload.response[key] : [...state[mappedKey], ...action.payload.response[key]];
    }
    return updateObj;
}

const updateSearchReducerTop = (state, action) => {
    const updateObj = {};
    for(const key in action.payload.response) {
        if(key == 'order') continue;
        const mappedKey = typeMapper(key);
        updateObj[mappedKey] = action.payload.shouldReset ? action.payload.response[key] : [...state.top[mappedKey], ...action.payload.response[key]];
    }
    return updateObj;
}

const typeMapper = (type) => {
        let newType = '';
        switch(type) {
            case 'name':
            newType = 'users';
            break;
            case 'topic':
            newType = 'questions';
            break;
            case 'reply':
            newType = 'replies';
            break;
            case 'group':
            newType = 'topics';
            break;
            case 'article':
            newType = 'articles';
            break;
            default:
            newType = 'top';
            break;
        }
        return newType;
    }
