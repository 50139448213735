/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading : true,
        articleData : []
    };
}

const INITIAL_STATE = IS();

export const authorArticle = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'START_AUTHOR_ARTICLE_FETCH':
            return {
                ...state,
                isLoading: false
            }       
        case 'COMPLETE_AUTHOR_ARTICLE_FETCH':
            return {
                ...state,
                articleData : action.payload
            }
          
        default:
            return state
    }
};