/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

import CONFIG from '../config'

const INITIAL_STATE = {
    ...CONFIG,
    dfp: {}
};

export const configReducer = (state = INITIAL_STATE, action) => {   
    switch (action.type) {
        case 'COMPLETE_DFP_AD_UNITS_FETCH':
            return {
                ...state,
                dfp: {
                    ...state.dfp,
                    ...action.payload
                }
            }
        default:
            return state
    }
};
