/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

const INITIAL_STATE = {
    videos: false,
    relatedVideos: [],
    mainVideo: {}
};

export const videosReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_VIDEO_FETCH':
            return {
                ...state,
                videos: true
            }
        case 'MAIN_VIDEO_FETCH':
            return {
                ...state,
                mainVideo: action.payload
            }
        case 'RELATED_VIDEOS_FETCH':
            return {
                ...state,
                relatedVideos: action.payload
            }
        default:
            return state
    }
};
