import axios from 'axios';
// import querystring from 'querystring';
import CONFIG, { defaultCountry, isBeYeuTemplate } from '../config';
// import bugsnag from '@bugsnag/js'
import {findTopicIndexById, findBoothIndexById, redirectBeYeuLogin} from '../helpers';
import { getCommunityAPIBase, getTapAPIBase} from '../config/apiConfig'

// const bugsnagClientNotify = (country, url, source, msg='', actionName = '') => {
//     const bugsnagClient = bugsnag({
//         apiKey: process.env.REACT_APP_ENV == 'production' ? '50d9c8e94db2e39314a6e781956bf8e2' : '3dddc9f4872d27251e09d84b89d84cac',
//         autoCaptureSessions: false
//     })

//     bugsnagClient.notify(new Error(),{
//         metaData: {
//             type: "request",
//             actionName: actionName,
//             country: country,
//             url: url,
//             source: source
//         },
//         severity: 'info'
//     })
    
//     if(process.env.REACT_APP_ENV !== 'production') {
//         console.log(`request | ${country} - ${url}`)
//     }
// }

// axios init
const buildAxios = (dispatch, getState, extension = `apiBase`) => {
    const state = getState(); 
    const params = {
        baseURL: getCommunityAPIBase(process.env.REACT_APP_ENV,extension)
        // timeout: 1000,    
    };

    if(state && state.userReducer.token) params['headers'] = {'Authorization': 'Bearer ' + state.userReducer.token};
    const client = axios.create(params);
    // Add a request interceptor
    client.interceptors.request.use((config) => {
        if(extension == 'apiBaseV3') {
            config.headers['country'] = state.appReducer.country;
            config.headers['lang'] = state.appReducer.locale;
            config.headers['deviceType'] = 'desktop';
            
            return config;
        }
        if(config.method == 'post') {
            config.data['country'] = state.appReducer.country;
            config.data['lang'] = state.appReducer.locale;
            config.data['deviceType'] = 'desktop';
            if(config.url.indexOf('/hashtag/feed') == -1){
                config.data['cn'] = state.appReducer.country; //deprecated
            } 
            config.data['is_web'] = 1; //deprecated
        } else {
            config.params['country'] = state.appReducer.country;
            config.params['lang'] = state.appReducer.locale;
            config.params['deviceType'] = 'desktop';
            if(config.url.indexOf('/hashtag/feed') == -1){
                config.params['cn'] = state.appReducer.country; //deprecated
            }
            config.params['is_web'] = 1; //deprecated
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    // Add a response interceptor
    client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        //if unauthorized
        if(error.response && error.response.status == 401) {
            dispatch(showLoginModal());
            //stop all loaders
            dispatch(resetAppLoader());
            dispatch(resetUserLoader());
            dispatch(resetFeedLoader());
            dispatch(resetPostLoader());
            dispatch(resetBoothLoader());
            dispatch(resetMemoryLoader());
        }
        return Promise.reject(error);
    });

    return client;
}

const buildAxiosBase = () => {
    const params = {
        baseURL: CONFIG['apiBase']
        // timeout: 1000,    
    };
    // if(state && state.userReducer.token) params['headers'] = {'Authorization': 'Bearer ' + state.userReducer.token};
    const client = axios.create(params);
    // Add a request interceptor
    client.interceptors.request.use((config) => {
        if(config.method == 'post') {
            config.data['deviceType'] = 'desktop';
        } else {
            config.params['deviceType'] = 'desktop';
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    // Add a response interceptor
    client.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        //if unauthorized
        if(error && error.response && error.response.status == 401) {            
        }
        return Promise.reject(error);
    });

    return client;
}

const buildAxiosSite = () => {
    const params = {
        baseURL: CONFIG['siteUrl']
        // timeout: 1000,    
    };
    const client = axios.create(params);

    return client;
}

const constructParams = (params, requestParams) => {
    if(params['method'] == 'post'){
        params['data'] = requestParams;
    } else if(params['method'] == 'get'){
        params['params'] = requestParams;
    }
    return params;
}

// export const refreshToken = (state={}) => {
//     if(state.userReducer.token) axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + state.userReducer.token;
//     return axiosClient;
// }

// axios.defaults.baseURL = CONFIG['apiBase'];
// axios.defaults.headers.common['Authorization'] = CONFIG['api']['token'];
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios init

////// - app - start
export const showLoginModal = () => {
    if (isBeYeuTemplate) {
        redirectBeYeuLogin()
        return { type: '' }
    }
    return {
        type: 'SHOW_LOGIN_MODAL'
    }
};

export const hideLoginModal = () => {
    return {
        type: 'HIDE_LOGIN_MODAL'
    }
};

export const addSystemMessage = (sData) => {
    return {
        type: 'ADD_SYSTEM_MESSAGE',
        payload: sData
    }
};

export const completeNotificationCountUpdate = (sData) => {
    return {
        type: 'COMPLETE_NOTIFICATION_COUNT_UPDATE',
        payload: sData
    }
};

export const startNotificationsFetch = () => {
    return {
        type: 'START_NOTIFICATIONS_FETCH'
    }
};

export const completeNotificationsFetch = (sData) => {
    return {
        type: 'COMPLETE_NOTIFICATIONS_FETCH',
        payload: sData
    }
};

export const completeRelatedPostsSidebar = (sData) => {
    return {
        type: 'COMPLETE_RELATED_POSTS_SIDEBAR',
        payload: sData
    }
};

export const resetAppLoader = () => {
    return {
        type: 'RESET_APP_LOADER'
    }
};

export const startUserFollowSuggestionsFetch = () => {
    return {
        type: 'START_USER_FOLLOW_SUGGESTIONS_FETCH'
    }
};

export const completeUserFollowSuggestionsFetch = (sData) => {
    return {
        type: 'COMPLETE_USER_FOLLOW_SUGGESTIONS_FETCH',
        payload: sData
    }
};

export const completeTopicTrendingListFetch = (sData) => {
    return {
        type: 'COMPLETE_TOPIC_TRENDING_LIST_FETCH',
        payload: sData
    }
};

export const startFeedbackSave = () => {
    return {
        type: 'START_FEEDBACK_SAVE'
    }
};

export const completeFeedbackSave = () => {
    return {
        type: 'COMPLETE_FEEDBACK_SAVE'
    }
};

export const startTapLogin = (sData) => {
    return {
        type: 'START_TAP_LOGIN',
        payload: sData
    }
};

export const completeTapLogin = () => {
    return {
        type: 'COMPLETE_TAP_LOGIN'
    }
};

export const startTapPollLogin = (sData) => {
    return {
        type: 'START_TAP_POLL_LOGIN',
        payload: sData
    }
};

export const completeTapPollLogin = () => {
    return {
        type: 'COMPLETE_TAP_POLL_LOGIN'
    }
};

export const completeTrackerTodaySidebar = (sData) => {
    return {
        type: 'COMPLETE_TRACKER_TODAY_SIDEBAR',
        payload: sData
    }
};

export const completeTapArticlesSidebar = (sData) => {
    return {
        type: 'COMPLETE_TAP_ARTICLES_SIDEBAR',
        payload: sData
    }
};

export const completeReportReasonsFetch = (sData) => {
    return {
        type: 'COMPLETE_REPORT_REASONS_FETCH',
        payload: sData
    }
};

export const updateTapPollMode = (sData) => {
    return {
        type: 'UPDATE_TAP_POLL_MODE',
        payload: sData
    }
};

export const completeSitemapFetch = (sData) => {
    return {
        type: 'COMPLETE_SITEMAP_FETCH',
        payload: sData
    }
};

export const updateDeviceType = (sData) => {
    return {
        type: 'UPDATE_DEVICE_TYPE',
        payload: sData
    }
};

export const setAppParams = (sData) => {
    return {
        type: 'SET_APP_PARAMS',
        payload: sData
    }
};

export const unsetAppParams = (sData) => {
    return {
        type: 'UNSET_APP_PARAMS',
        payload: sData
    }
};

export const completePasswordResetVerifyToken = (sData) => {
    return {
        type: 'COMPLETE_PASSWORD_RESET_TOKEN',
        payload: sData
    }
};

export const completePasswordReset = (sData) => {
    return {
        type: 'COMPLETE_PASSWORD_RESET',
        payload: sData
    }
};

export const completeForgotPassword = (sData) => {
    return {
        type: 'COMPLETE_FORGOT_PASSWORD',
        payload: sData
    }
};
////// - app - end

////// - user - start
export const startUserAuthentication = () => {
    return {
        type: 'START_USER_AUTHENTICATION'
    }
};

export const completeUserAuthentication = (sData) => {
    return {
        type: 'COMPLETE_USER_AUTHENTICATION',
        payload: sData
    }
};

export const completeUserOnboarding = (sData) => {
    return {
        type: 'COMPLETE_USER_ONBOARDING',
        payload: sData
    }
};

export const startAuthorFollowersFetch = (payload) => {
    return {
        type: 'START_USER_FOLLOWERS_FETCH',
        payload
    }
};

export const completeAuthorFollowersFetch = (sData) => {
    return {
        type: 'COMPLETE_USER_FOLLOWERS_FETCH',
        payload: sData
    }
};

export const startAuthorFollowingFetch = (payload) => {
    return {
        type: 'START_USER_FOLLOWING_FETCH',
        payload
    }
};

export const completeAuthorFollowingFetch = (sData) => {
    return {
        type: 'COMPLETE_USER_FOLLOWING_FETCH',
        payload: sData
    }
};

export const startAuthorRolesFetch = () => {
    return {
        type: 'START_AUTHOR_ROLES_FETCH'
    }
}

export const completeAuthorRolesFetch = (sData) => {
    return {
        type: 'COMPLETE_AUTHOR_ROLES_FETCH',
        payload: sData
    }
}

export const resetUserLoader = () => {
    return {
        type: 'RESET_USER_LOADER'
    }
};

export const startAuthorProfileUpdate = () => {
    return {
        type: 'START_USER_PROFILE_UPDATE'
    }
};

export const completeAuthorProfileUpdate = (sData) => {
    return {
        type: 'COMPLETE_USER_PROFILE_UPDATE',
        payload: sData
    }
};
////// - user - end

////// - feed - start
export const startFeedFetch = () => {
    return {
        type: 'START_FEED_FETCH'
    }
};

export const completeFeedFetch = (sData) => {
    return {
        type: 'COMPLETE_FEED_FETCH',
        payload: sData
    }
};

export const startFeedQuestionReply = () => {
    return {
        type: 'START_FEED_QUESTION_REPLY'
    }
};

export const completeFeedQuestionReply = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_REPLY',
        payload: sData
    }
};

export const startFeedPollVote = () => {
    return {
        type: 'START_FEED_POLL_VOTE'
    }
};

export const completeFeedPollVote = (sData) => {
    return {
        type: 'COMPLETE_FEED_POLL_VOTE',
        payload: sData
    }
};

export const completeFeedQuestionLike = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_LIKE',
        payload: sData
    }
};

export const completeFeedQuestionUnlike = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_UNLIKE',
        payload: sData
    }
};

export const startFeedQuestionHide = () => {
    return {
        type: 'START_FEED_QUESTION_HIDE'
    }
};

export const completeFeedQuestionHide = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_HIDE',
        payload: sData
    }
};

export const startFeedQuestionDelete = () => {
    return {
        type: 'START_FEED_QUESTION_DELETE'
    }
};

export const completeFeedQuestionDelete = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_DELETE',
        payload: sData
    }
};

export const startFeedReplyDelete = () => {
    return {
        type: 'START_FEED_REPLY_DELETE'
    }
};

export const completeFeedReplyDelete = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_DELETE',
        payload: sData
    }
};

export const startFeedReplyCommentDelete = () => {
    return {
        type: 'START_FEED_REPLY_COMMENT_DELETE'
    }
};

export const completeFeedReplyCommentDelete = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_COMMENT_DELETE',
        payload: sData
    }
};

export const startFeedQuestionEdit = () => {
    return {
        type: 'START_FEED_QUESTION_EDIT'
    }
};

export const completeFeedQuestionEdit = (sData) => {
    return {
        type: 'COMPLETE_FEED_QUESTION_EDIT',
        payload: sData
    }
};

export const startFeedReplyEdit = () => {
    return {
        type: 'START_FEED_REPLY_EDIT'
    }
};

export const completeFeedReplyEdit = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_EDIT',
        payload: sData
    }
};

export const completeFeedReplyLike = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_LIKE',
        payload: sData
    }
};

export const completeFeedReplyDislike = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_DISLIKE',
        payload: sData
    }
};

export const startFeedReplyCommentSave = () => {
    return {
        type: 'START_FEED_REPLY_COMMENT_SAVE'
    }
};

export const completeFeedActionsUpdate = (sData) => {
    return {
        type: 'COMPLETE_FEED_ACTIONS_UPDATE',
        payload: sData
    }
};

export const completeFeedReplyCommentSave = (sData) => {
    return {
        type: 'COMPLETE_FEED_REPLY_COMMENT_SAVE',
        payload: sData
    }
};

export const resetFeedLoader = () => {
    return {
        type: 'RESET_FEED_LOADER'
    }
};
////// - feed - end

////// - post - start
export const startPostFetch = () => {
    return {
        type: 'START_POST_FETCH'
    }
};

export const completePostFetch = (sData) => {
    return {
        type: 'COMPLETE_POST_FETCH',
        payload: sData
    }
};

export const startPostPollVote = () => {
    return {
        type: 'START_POST_POLL_VOTE'
    }
};

export const completePostPollVote = (sData) => {
    return {
        type: 'COMPLETE_POST_POLL_VOTE',
        payload: sData
    }
};

export const startPostQuestionReply = () => {
    return {
        type: 'START_POST_QUESTION_REPLY'
    }
};

export const completePostQuestionReply = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_REPLY',
        payload: sData
    }
};

export const completePostQuestionLike = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_LIKE',
        payload: sData
    }
};

export const completePostQuestionUnlike = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_UNLIKE',
        payload: sData
    }
};

export const startPostQuestionHide = () => {
    return {
        type: 'START_POST_QUESTION_HIDE'
    }
};

export const completePostQuestionHide = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_HIDE',
        payload: sData
    }
};

export const startPostQuestionDelete = () => {
    return {
        type: 'START_POST_QUESTION_DELETE'
    }
};

export const completePostQuestionDelete = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_DELETE',
        payload: sData
    }
};

export const startPostReplyDelete = () => {
    return {
        type: 'START_POST_REPLY_DELETE'
    }
};

export const completePostReplyDelete = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_DELETE',
        payload: sData
    }
};

export const startPostReplyCommentDelete = () => {
    return {
        type: 'START_POST_REPLY_COMMENT_DELETE'
    }
};

export const completePostReplyCommentDelete = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_COMMENT_DELETE',
        payload: sData
    }
};

export const startPostQuestionEdit = () => {
    return {
        type: 'START_POST_QUESTION_EDIT'
    }
};

export const completePostQuestionEdit = (sData) => {
    return {
        type: 'COMPLETE_POST_QUESTION_EDIT',
        payload: sData
    }
};

export const startPostReplyEdit = () => {
    return {
        type: 'START_POST_REPLY_EDIT'
    }
};

export const completePostReplyEdit = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_EDIT',
        payload: sData
    }
};

export const completePostReplyLike = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_LIKE',
        payload: sData
    }
};

export const completePostReplyDislike = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_DISLIKE',
        payload: sData
    }
};

export const startPostReplyCommentSave = () => {
    return {
        type: 'START_POST_REPLY_COMMENT_SAVE'
    }
};

export const completePostReplyCommentSave = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLY_COMMENT_SAVE',
        payload: sData
    }
};

export const startPostRepliesFetch = () => {
    return {
        type: 'START_POST_REPLIES_FETCH'
    }
};

export const completePostRepliesFetch = (sData) => {
    return {
        type: 'COMPLETE_POST_REPLIES_FETCH',
        payload: sData
    }
};

export const startPostCommentsFetch = () => {
    return {
        type: 'START_POST_COMMENTS_FETCH'
    }
};

export const completePostCommentsFetch = (sData) => {
    return {
        type: 'COMPLETE_POST_COMMENTS_FETCH',
        payload: sData
    }
};

export const completePostActionsUpdate = (sData) => {
    return {
        type: 'COMPLETE_POST_ACTIONS_UPDATE',
        payload: sData
    }
};

export const resetPostLoader = () => {
    return {
        type: 'RESET_POST_LOADER'
    }
};
////// - post - end

////// - booth - start
export const startBoothFetch = () => {
    return {
        type: 'START_BOOTH_FETCH'
    }
};

export const completeBoothFetch = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_FETCH',
        payload: sData
    }
};

export const startBoothCommentSave = () => {
    return {
        type: 'START_BOOTH_COMMENT_SAVE'
    }
};

export const completeBoothCommentSave = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_COMMENT_SAVE',
        payload: sData
    }
};

export const startBoothCaptionEdit = () => {
    return {
        type: 'START_BOOTH_CAPTION_EDIT'
    }
};

export const completeBoothCaptionEdit = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_CAPTION_EDIT',
        payload: sData
    }
};

export const startBoothDelete = () => {
    return {
        type: 'START_BOOTH_DELETE'
    }
};

export const completeBoothDelete = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_DELETE',
        payload: sData
    }
};

export const startBoothCommentDelete = () => {
    return {
        type: 'START_BOOTH_COMMENT_DELETE'
    }
};

export const completeBoothCommentDelete = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_COMMENT_DELETE',
        payload: sData
    }
};

export const completeBoothLike = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_LIKE',
        payload: sData
    }
};

export const completeBoothUnlike = (sData) => {
    return {
        type: 'COMPLETE_BOOTH_UNLIKE',
        payload: sData
    }
};

export const resetBoothLoader = () => {
    return {
        type: 'RESET_BOOTH_LOADER'
    }
};

export const completeMemoryLike = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_LIKE',
        payload: sData
    }
};

export const completeMemoryUnlike = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_UNLIKE',
        payload: sData
    }
};

export const startMemoryCommentDelete = () => {
    return {
        type: 'START_MEMORY_COMMENT_DELETE'
    }
};

export const completeMemoryCommentDelete = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_COMMENT_DELETE',
        payload: sData
    }
};

export const startMemoryDelete = () => {
    return {
        type: 'START_MEMORY_DELETE'
    }
};

export const completeMemoryDelete = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_DELETE',
        payload: sData
    }
};

export const startMemoryCaptionEdit = () => {
    return {
        type: 'START_MEMORY_CAPTION_EDIT'
    }
};

export const completeMemoryCaptionEdit = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_CAPTION_EDIT',
        payload: sData
    }
};

export const startMemoryCommentSave = () => {
    return {
        type: 'START_MEMORY_COMMENT_SAVE'
    }
};

export const completeMemoryCommentSave = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_COMMENT_SAVE',
        payload: sData
    }
};

export const startMemoryFetch = () => {
    return {
        type: 'START_MEMORY_FETCH'
    }
};

export const completeMemoryFetch = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_FETCH',
        payload: sData
    }
};

export const startMemoryCommentsFetch = () => {
    return {
        type: 'START_MEMORY_COMMENTS_FETCH'
    }
};

export const completeMemoryCommentsFetch = (sData) => {
    return {
        type: 'COMPLETE_MEMORY_COMMENTS_FETCH',
        payload: sData
    }
};

export const resetMemoryLoader = () => {
    return {
        type: 'RESET_MEMORY_LOADER'
    }
};
///// - booth - end

///// - compose - start
export const startCompose = () => {
    return {
        type: 'START_COMPOSE'
    }
};

export const completeCompose = () => {
    return {
        type: 'COMPLETE_COMPOSE'
    }
};

export const resetCompose = () => {
    return {
        type: 'RESET_COMPOSE'
    }
};

export const completeLinkPreviewFetch = (sData) => {
    return {
        type: 'COMPLETE_LINK_PREVIEW_FETCH',
        payload: sData
    }
};

export const completeLinkPreviewReset = () => {
    return {
        type: 'COMPLETE_LINK_PREVIEW_RESET'
    }
};
///// - compose - end

///// - topic - start
export const startTopicListFetch = () => {
    return {
        type: 'START_TOPIC_LIST_FETCH'
    }
};

export const completeTopicListFetch = (sData) => {
    return {
        type: 'COMPLETE_TOPIC_LIST_FETCH',
        payload: sData
    }
};

export const completeTopicRelatedListFetch = (sData) => {
    return {
        type: 'COMPLETE_TOPIC_RELATED_LIST_FETCH',
        payload: sData
    }
};

export const startTopicSuggestionsFetch = () => {
    return {
        type: 'START_TOPIC_SUGGESTIONS_FETCH'
    }
};

export const completeTopicSuggestionsFetch = (sData) => {
    return {
        type: 'COMPLETE_TOPIC_SUGGESTIONS_FETCH',
        payload: sData
    }
};
///// - topic - end

//// - video - start
export const startVideoFetch = () => {
    return {
        type: 'START_VIDEO_FETCH'
    }
}
//// - video - end

//// - main video - start
export const mainVideoFetch = (sData) => {
    return {
        type: 'MAIN_VIDEO_FETCH',
        payload: sData
    }
}
//// - main video - end

//// - related videos - start
export const relatedVideosFetch = (sData) => {
    return {
        type: 'RELATED_VIDEOS_FETCH',
        payload: sData
    }
}
//// - related videos - end

///// - search - start
export const startSearch = () => {
    return {
        type: 'START_SEARCH'
    }
};

export const completeSearchTop = (sData) => {
    return {
        type: 'COMPLETE_SEARCH_TOP',
        payload: sData
    }
};

export const completeSearch = (sData) => {
    return {
        type: 'COMPLETE_SEARCH',
        payload: sData
    }
};
///// - search - end

///// - search - start
export const startStaticContentFetch = () => {
    return {
        type: 'START_STATIC_CONTENT_FETCH'
    }
};

export const completeStaticContentFetch = (sData) => {
    return {
        type: 'COMPLETE_STATIC_CONTENT_FETCH',
        payload: sData
    }
};
///// - search - start

///// - tracker - start
export const startTrackerCalendarFetch = () => {
    return {
        type: 'START_TRACKER_CALENDAR_FETCH'
    }
};

export const completeTrackerCalendarFetch = (sData) => {
    return {
        type: 'COMPLETE_TRACKER_CALENDAR_FETCH',
        payload: sData
    }
};

export const startTrackerPregnancyDetailsFetch = () => {
    return {
        type: 'START_TRACKER_PREGNANCY_DETAILS_FETCH'
    }
};

export const completeTrackerPregnancyDetailsFetch = (sData) => {
    return {
        type: 'COMPLETE_TRACKER_PREGNANCY_DETAILS_FETCH',
        payload: sData
    }
};

export const startTrackerBabyDetailsFetch = () => {
    return {
        type: 'START_TRACKER_BABY_DETAILS_FETCH'
    }
};

export const completeTrackerBabyDetailsFetch = (sData) => {
    return {
        type: 'COMPLETE_TRACKER_BABY_DETAILS_FETCH',
        payload: sData
    }
};
///// - tracker - end

///// - contests - start
export const startContestListFetch = () => {
    return {
        type: 'START_CONTEST_LIST_FETCH'
    }
};

export const completeContestListFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTEST_LIST_FETCH',
        payload: sData
    }
};

export const startContestDetailsFetch = () => {
    return {
        type: 'START_CONTEST_DETAILS_FETCH'
    }
};

export const completeContestDetailsFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTEST_DETAILS_FETCH',
        payload: sData
    }
};

export const completeContestMoreListFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTEST_MORE_LIST_FETCH',
        payload: sData
    }
};
///// - contests - end


export const localeUpdate = (sData) => {
    return {
        type: 'LOCALE_UPDATE',
        payload: sData
    }
}

//// - rewards -starts 
export const startRewardsListFetch = () => {
    return {
        type: 'START_REWARDS_LIST_FETCH'
    }
};

export const completeRewardsListFetch = (sData) => {
    return {
        type: 'COMPLETE_REWARDS_LIST_FETCH',
        payload: sData
    }
};

export const startRewardDetailsFetch = () => {
    return {
        type: 'START_REWARD_DETAILS_FETCH'
    }
};

export const completeRewardDetailsFetch = (sData) => {
    return {
        type: 'COMPLETE_REWARD_DETAILS_FETCH',
        payload: sData
    }
};

//// - Covid -starts 
export const startCovidLandingPageFetch = () => {
    return {
        type: 'START_COVID_LANDING_PAGE_FETCH'
    }
};

export const completeCovidLandingPageFetch = (sData) => {
    return {
        type: 'COMPLETE_COVID_LANDING_PAGE_FETCH',
        payload: sData
    }
};

//// - Covid -load more
export const startCovidLoadMoreData = () => {
    return {
        type: 'START_COVID_LOAD_MORE_dATA'
    }
};

export const completeCovidLoadMoreData = (sData) => {
    return {
        type: 'COMPLETE_COVID_LOAD_MORE_DATA',
        payload: sData
    }
};

// covid- category wise QnA
export const startCovidCotegoryQnAFetch = () => {
    return {
        type: 'START_COVID_CATEGORY_QNA_FETCH'
    }
};

export const completeCovidCotegoryQnAFetch = (sData) => {
    return {
        type: 'COMPLETE_COVID_CATEGORY_QNA_FETCH',
        payload: sData
    }
};


//// - covid details fetch 
export const startCovidAnswerFetch = () => {
    return {
        type: 'START_COVID_ANSWER_FETCH'
    }
};

export const completeCovidAnswerFetch = (sData) => {
    return {
        type: 'COMPLETE_COVID_ANSWER_FETCH',
        payload: sData
    }
};

//
export const startAddCovidCotegoryQnAFetch = () => {
    return {
        type: 'START_ADD_COVID_CATEGORY_QNA_FETCH'
    }
};

export const completeAddCovidCotegoryQnAFetch = (sData) => {
    return {
        type: 'COMPLETE_ADD_COVID_CATEGORY_QNA_FETCH',
        payload: sData
    }
};

export const showInvalidMessage = (sData) => {
    return {
        type: 'SHOW_INVALID_MESSAGE',
        payload: sData
    }
}

export const showInvalidMemoryMessage = (sData) => {
    return {
        type: 'SHOW_INVALID_MEMORY_MESSAGE',
        payload: sData
    }
}

export const startFoodLandingDataFetch = () => {
    return {
        type: 'START_FOOD_LANDING_DATA_FETCH',
    }
}

export const completeFoodLandingDataFetch = (sData) => {
    return {
        type: 'COMPLETE_FOOD_LANDING_DATA_FETCH',
        payload: sData
    }
}

export const startFoodListingDataFetch = (sData) => {
    return {
        type: 'START_FOOD_LISTING_DATA_FETCH',
        payload: sData
    }
}

export const completeFoodListingDataFetch = (sData) => {
    return {
        type: 'COMPLETE_FOOD_LISTING_DATA_FETCH',
        payload: sData
    }
}

export const startFoodDetailDataFetch = (sData) => {
    return {
        type: 'START_FOOD_DETAIL_DATA_FETCH',
        payload: sData
    }
}

export const completeFoodDetailDataFetch = (sData) => {
    return {
        type: 'COMPLETE_FOOD_DETAIL_DATA_FETCH',
        payload: sData
    }
}

export const startRecipePageDataFetch = () => {
    return {
        type: 'START_RECIPE_PAGE_DATA_FETCH'
    }
}

export const completeRecipePageDataFetch = () => {
    return {
        type: 'COMPLETE_RECIPE_PAGE_DATA_FETCH'
    }
}

export const completeRecipeDetailDataFetch = (sData) => {
    return {
        type: 'COMPLETE_RECIPE_DETAIL_DATA_FETCH',
        payload: sData
    }
} 

export const completeRecipesListingDataFetch = (sData) => {
    return {
        type: 'COMPLETE_RECIPES_LISTING_DATA_FETCH',
        payload: sData
    }
} 

export const completeRecipesCategoryDataFetch = (sData) => {
    return {
        type: 'COMPLETE_RECIPES_CATEGORY_DATA_FETCH',
        payload: sData
    }
}

export const completeRecipesLandingDataFetch = (sData) => {
    return {
        type: 'COMPLETE_RECIPES_LANDING_DATA_FETCH',
        payload: sData
    }
}

export const completeHeaderMenuFetch = (sData) => {
    return {
        type: 'COMPLETE_HEADER_MENU_FETCH',
        payload: sData
    }
}

export const resetFoodLoader = () => {
    return {
        type: 'RESET_FOOD_LOADER'
    }
}

export const startSitemapHomeDataFetch = () => {
    return {
        type: 'START_SITEMAP_HOME_DATA_FETCH'
    }
}

export const completeSitemapHomeDataFetch = (sData) => {
    return {
        type: 'COMPLETE_SITEMAP_HOME_DATA_FETCH',
        payload: sData
    }
}

export const startSitemapArticleByYearDataFetch = () => {
    return {
        type: 'START_SITEMAP_ARTICLE_BY_YEAR_DATA_FETCH'
    }
}

export const completeSitemapArticleByYearDataFetch = (sData) => {
    return {
        type: 'COMPLETE_SITEMAP_ARTICLE_BY_YEAR_DATA_FETCH',
        payload: sData
    }
}

export const startSitemapArticleByWeekDataFetch = () => {
    return {
        type: 'START_SITEMAP_ARTICLE_BY_WEEK_DATA_FETCH'
    }
}

export const completeSitemapArticleByWeekDataFetch = (sData) => {
    return {
        type: 'COMPLETE_SITEMAP_ARTICLE_BY_WEEK_DATA_FETCH',
        payload: sData
    }
}

export const completeFiltersFetch = (sData) => {
    return {
        type: 'COMPLETE_FILTERS_FETCH',
        payload: sData
    }
}

export const completeDfpAdUnitsFetch = (sData) => {
    return {
        type: 'COMPLETE_DFP_AD_UNITS_FETCH',
        payload: sData
    }
}


export const startContentHubDataFetch = () => {
    return {
        type: 'START_CONTENTHUB_FETCH'
    }
}

export const completeContentHubFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTENTHUB_FETCH',
        payload: sData
    }
};

export const startContentHubFeedFetch = () => {
    return {
        type: 'START_CONTENTHUB_FEED_FETCH'
    }
};

export const completeContentHubFeedFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTENTHUB_FEED_FETCH',
        payload: sData
    }
};

export const startContentHubDetailPageDataFetch = () => {
    return {
        type: 'START_CONTENTHUBDETAILPAGE_FETCH'
    }
}

export const completeContentHubDetailPageFetch = (sData) => {
    return {
        type: 'COMPLETE_CONTENTHUBDETAILPAGE_FETCH',
        payload: sData
    }
};

export const startAuthorArticleFetch = () => {
    return {
        type: 'START_AUTHOR_ARTICLE_FETCH'
    }
};
    
export const completeAuthorArticleFetch = (sData) => {
    return {
        type: 'COMPLETE_AUTHOR_ARTICLE_FETCH',
        payload: sData
    }
};

export const startBlockUserListFetch = () => {
    return {
        type: 'START_BLOCK_USER_LIST_FETCH'
    }
};
    
export const completeBlockUserListFetch = (sData) => {
    return {
        type: 'COMPLETE_BLOCK_USER_LIST_FETCH',
        payload: sData
    }
};

export const startBlockListRemoveUser = () => {
    return {
        type: 'START_BLOCK_LIST_REMOVE_USER'
    }
};
    
export const completeBlockListRemoveUser = (sData) => {
    return {
        type: 'COMPLETE_BLOCK_LIST_REMOVE_USER',
        payload: sData
    }
};

export const startUserSettingsFetch = () => {
    return {
        type: 'START_USER_SETTINGS_FETCH'
    }
};

export const completeUserSettingsFetch = (sData) => {
    return {
        type: 'COMPLETE_USER_SETTINGS_FETCH',
        payload: sData
    }
};

export const startUserSettingsUpdate = () => {
    return {
        type: 'START_USER_SETTINGS_UPDATE'
    }
};

export const completeUserSettingsUpdate = (sData) => {
    return {
        type: 'COMPLETE_USER_SETTINGS_UPDATE',
        payload: sData
    }
};

export const updateDataSharingStatus = (sData) => {
    return {
        type: 'UPDATE_DATA_SHARING_STATUS',
        payload: sData
    }
}

////// - actionable

export const feedFetch = (subfilter, page, shouldReset, accessedFrom, feed_type = null, feed_type_id = null, ifServerFilled = false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['feed']['url'],
            method: CONFIG['api']['feed']['method']
        },
        { page: page, type: subfilter, ...(feed_type && {feed_type}), ...(feed_type_id && {feed_type_id}) }
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFilter = feed_type; // home_feed, birth clubs
                response.currentFilterId = feed_type_id;
                response.currentFeed = subfilter; // newsfeed, latest, popular
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                // response.filter = filter;
                response.ifServerFilled = ifServerFilled;         
                dispatch(completeFeedFetch(response));
                dispatch(completeNotificationCountUpdate({notificationCount: response.notif_count}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const feedFiltersFetch = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['feedFilters']['url'],
            method: CONFIG['api']['feedFilters']['method']
        },
        {}
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.response) {
                dispatch(completeFiltersFetch(xhrResponse.data.response.filters));
                return xhrResponse.data.response.filters;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const hashFeedFetch = (hashtag, page, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['hashFeed']['url'],
            method: CONFIG['api']['hashFeed']['method']
        },
        { hashtag: hashtag, page: page }
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = 'hash';
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;                
                dispatch(completeFeedFetch(response));
                dispatch(completeNotificationCountUpdate({notificationCount: response.notif_count}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const unansweredFeedFetch = (type, countryId, answers=0, page, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['unansweredFeedFetch']['url'],
            method: CONFIG['api']['unansweredFeedFetch']['method']
        },
        { countryId, answers, page, type, ...(filter && {filter}) }
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = type;
                response.countryId = countryId;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;                
                dispatch(completeFeedFetch(response));
                dispatch(completeNotificationCountUpdate({notificationCount: response.notif_count}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const videosFetch = (videoId, page=1) => {
    const params = constructParams(
        {
            url: CONFIG['api']['videosFetch']['url'],
            method: CONFIG['api']['videosFetch']['method']
        },
        {mediaKey: videoId, page: page}
    );
    return (dispatch, getState) => {
        dispatch(startVideoFetch());

        return buildAxios(dispatch, getState).request(params)
        .then(xhrResponse => {
            if(xhrResponse.status == 200){
                const response = xhrResponse.data;
                dispatch(mainVideoFetch(response.response.main_media));
                dispatch(relatedVideosFetch(response.response.web_media));    
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }
}

export const postFetch = (id, replyId=null, commentId=null, page=null) => {
    const params = constructParams(
        {
            url: CONFIG['api']['postFetch']['url'],            
            method: CONFIG['api']['postFetch']['method']
        },
        {questionId: id, ...(replyId && {answerId: replyId}), ...(replyId && commentId && {commentId: commentId}) ,...(page && {page: page})}
    );
    return (dispatch, getState) => {        
        // if(getState().appReducer.isCrawler) params.params['allReplies'] = 1;
        dispatch(startPostFetch());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then(xhrResponse => {
            if(xhrResponse.status == 200 && xhrResponse.data.status) {
                dispatch(completePostFetch(xhrResponse.data));
                dispatch(completeRelatedPostsSidebar(xhrResponse.data));
                dispatch(completeNotificationCountUpdate({notificationCount: xhrResponse.data.notif_count}));

                return xhrResponse.data;
            }
            else if(xhrResponse.data && xhrResponse.data.response && xhrResponse.data.response.block_user && xhrResponse.data.response.block_user.user_id && xhrResponse.data.response.block_user.anonymous_status === 0 && getState().userReducer.id && xhrResponse.data.response.block_user.user_id === getState().userReducer.id){
                return xhrResponse.data.response;
            }
            else {
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const postAmpFetch = (id, page = null) => {
    const params = constructParams(
        {
            url: CONFIG['api']['postFetch']['url'],            
            method: CONFIG['api']['postFetch']['method']
        },
        { questionId: id, ...(page && {page: page}) }
    );        
    //request
    return buildAxiosBase()
        .request(params)
        .then((xhrResponse) => {
            return xhrResponse;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const tapPollFetch = (id, replyId=null, commentId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['tapPollFetch']['url'],            
            method: CONFIG['api']['tapPollFetch']['method']
        },
        {questionId: id, ...(replyId && {answerId: replyId}), ...(replyId && commentId && {commentId: commentId})}
    );
    return (dispatch, getState) => {
        dispatch(startPostFetch());        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePostFetch(xhrResponse.data));
                dispatch(completeRelatedPostsSidebar(xhrResponse.data));
                dispatch(completeNotificationCountUpdate({notificationCount: xhrResponse.data.notif_count}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const pollVote = (postId, cardIndex, inFeed, pollId, choiceId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['pollVote']['url'],            
            method: CONFIG['api']['pollVote']['method']
        },
        {pollId: pollId, choiceId: choiceId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startFeedPollVote());
        } else {
            dispatch(startPostPollVote());
            dispatch(startFeedPollVote());
        }
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.postId = postId;
                if(inFeed) {
                    dispatch(completeFeedPollVote(response));
                } else {
                    dispatch(completePostPollVote(response));
                    dispatch(completeFeedPollVote(response));
                }
                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionReply = (questionId, message, anonymous, isActive, image) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionReply']['url'],            
            method: CONFIG['api']['questionReply']['method']
        },
        {questionId, message, anonymous, isActive, ...(image && {image})}
    );
    return (dispatch, getState) => {
        dispatch(startPostQuestionReply());
        dispatch(startFeedQuestionReply());
        //request
        // axiosClient.defaults.headers.post['content-type'] = 'multipart/form-data';
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status) {
                    const response = xhrResponse.data;
                    response.questionId = questionId;
                    dispatch(completePostQuestionReply(response));
                    dispatch(completeFeedQuestionReply(response));
                } else {
                    dispatch(showInvalidMessage({message: xhrResponse.data.message}))
                    resetPostLoader();
                }                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionFollow = (questionId, inFeed) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionFollow']['url'],            
            method: CONFIG['api']['questionFollow']['method']
        },
        {questionId}
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.questionId = questionId;
                if(inFeed) {
                    dispatch(completeFeedActionsUpdate(response));
                } else {
                    dispatch(completeFeedActionsUpdate(response));
                    dispatch(completePostActionsUpdate(response));
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionUnfollow = (questionId, inFeed) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionUnfollow']['url'],            
            method: CONFIG['api']['questionUnfollow']['method']
        },
        {questionId}
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.questionId = questionId;
                if(inFeed) {
                    dispatch(completeFeedActionsUpdate(response));
                } else {
                    dispatch(completeFeedActionsUpdate(response));
                    dispatch(completePostActionsUpdate(response));
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const blockUnblockUser = (entity_type, entity_id, status, type) => {
    const params = constructParams(
        {
            url: CONFIG['api']['blockUnblockUser']['url'],            
            method: CONFIG['api']['blockUnblockUser']['method']
        },
        {entity_type, entity_id, status}
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(type === 'question' || type === 'booth'){
                    const questionId = entity_id
                    dispatch(completeFeedQuestionHide({questionId}));
                }
                else if(type === 'blockList'){
                    const userId = entity_id
                    dispatch(completeBlockListRemoveUser({userId}));
                }
                return xhrResponse
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyCommentSave = (postId, replyIndex, replyId, message, anonymous) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['replyCommentSave']['url'],            
            method: CONFIG['api']['replyCommentSave']['method']
        },
        {replyId, message, anonymous}
    );
    return (dispatch, getState) => {
        dispatch(startPostReplyCommentSave());
        dispatch(startFeedReplyCommentSave());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.postId = postId;
                response.replyIndex = replyIndex;
                response.replyId = replyId;
                if(xhrResponse.data.status){
                    dispatch(completePostReplyCommentSave(response));
                    dispatch(completeFeedReplyCommentSave(response));
                }
                else{
                    dispatch(showInvalidMessage({message: xhrResponse.data.message}))
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionLike = (cardIndex, inFeed, questionId) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionLike']['url'],            
            method: CONFIG['api']['questionLike']['method']
        },
        {questionId}
    );
    return (dispatch, getState) => {
        const index = findTopicIndexById(questionId, getState().feedReducer.feedList);
        const feedLikeCount = index < 0 ? 0 : getState().feedReducer.feedList[index].likes.count;
        const postLikeCount = getState().postReducer.data.likes && getState().postReducer.data.likes.count;
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const liked = true;
                if(inFeed) {
                    dispatch(completeFeedQuestionLike({questionId, feedLikeCount, liked}));
                } else {
                    dispatch(completeFeedQuestionLike({questionId, feedLikeCount, liked}));
                    dispatch(completePostQuestionLike({questionId, postLikeCount, liked}));
                }                
            }
            else{
                return xhrResponse.data.message
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionUnlike = (cardIndex, inFeed, questionId) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionUnlike']['url'],            
            method: CONFIG['api']['questionUnlike']['method']
        },
        {questionId}
    );
    return (dispatch, getState) => {
        const index = findTopicIndexById(questionId, getState().feedReducer.feedList);
        const feedLikeCount = index < 0 ? 0 : getState().feedReducer.feedList[index].likes.count;
        const postLikeCount = getState().postReducer.data.likes && getState().postReducer.data.likes.count;
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const liked = false;
                if(inFeed) {
                    dispatch(completeFeedQuestionUnlike({questionId, feedLikeCount, liked}));
                } else {
                    dispatch(completeFeedQuestionUnlike({questionId, feedLikeCount, liked}));
                    dispatch(completePostQuestionUnlike({questionId, postLikeCount, liked}));
                }                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyLike = (postId, inFeed, answerId, action) => {
    const params = constructParams(
        {
            url: CONFIG['api']['replyLike']['url'],            
            method: CONFIG['api']['replyLike']['method']
        },
        {action, answerId}
    );
    return (dispatch, getState) => {
        // let subIndex = findIndexById(answerId, getState().postReducer.data.replies);
        // let replyLikeCount = subIndex < 0 ? 0 : getState().postReducer.data.replies[subIndex].likes.count;
        // let feedIndex = findTopicIndexById(postId, getState().feedReducer.feedList);
        // let feedSubIndex = feedIndex < 0 ? 0 : findIndexById(answerId, getState().feedReducer.feedList[feedIndex].replies);
        // let feedReplyLikeCount = feedIndex < 0 ? 0 : (feedSubIndex < 0 ? 0 : getState().feedReducer.feedList[feedIndex].replies[feedSubIndex].likes.count);
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const likes = xhrResponse.data.response.likes;
                const dislikes = xhrResponse.data.response.dislikes;
                if(inFeed) {
                    dispatch(completeFeedReplyLike({postId, replyId: answerId, likes, dislikes}));
                } else {
                    dispatch(completeFeedReplyLike({postId, replyId: answerId, likes, dislikes}));
                    dispatch(completePostReplyLike({replyId: answerId, likes, dislikes}));
                }
                
            }
            else{
                return xhrResponse.data.message;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyDislike = (postId, inFeed, answerId, action) => {
    const params = constructParams(
        {
            url: CONFIG['api']['replyDislike']['url'],            
            method: CONFIG['api']['replyDislike']['method']
        },
        {action, answerId}
    );
    return (dispatch, getState) => {
        // let subIndex = findIndexById(answerId, getState().postReducer.data.replies);
        // let replyLikeCount = subIndex < 0 ? 0 : getState().postReducer.data.replies[subIndex].likes.count;
        // let feedIndex = findTopicIndexById(postId, getState().feedReducer.feedList);
        // let feedSubIndex = feedIndex < 0 ? 0 : findIndexById(answerId, getState().feedReducer.feedList[feedIndex].replies);
        // let feedReplyLikeCount = feedIndex < 0 ? 0 : (feedSubIndex < 0 ? 0 : getState().feedReducer.feedList[feedIndex].replies[feedSubIndex].likes.count);
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const likes = xhrResponse.data.response.likes;
                const dislikes = xhrResponse.data.response.dislikes;
                if(inFeed) {
                    dispatch(completeFeedReplyDislike({postId, replyId: answerId, likes, dislikes}));
                } else {
                    dispatch(completeFeedReplyDislike({postId, replyId: answerId, likes, dislikes}));
                    dispatch(completePostReplyDislike({replyId: answerId, likes, dislikes}));
                }                
            }
            else{
                return xhrResponse.data.message;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const report = (type, entityId, reasonId, comment, entityReasonId=null) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['report']['url'],            
            method: CONFIG['api']['report']['method']
        },
        { type, entityId, reasonId, comment, ...(entityReasonId && {entityReasonId}) }
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {}
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const reportReasonsFetch = (type) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['reportReasonsFetch']['url'],            
            method: CONFIG['api']['reportReasonsFetch']['method']
        },
        { type }
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeReportReasonsFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionHide = (hideUrl, questionId, inFeed) => {    
    const params = constructParams(
        {
            url: hideUrl,            
            method: 'post'
        },
        {}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startFeedQuestionHide({questionId}));
        } else {
            dispatch(startPostQuestionHide({questionId}));
            dispatch(startFeedQuestionHide({questionId}));
        }
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(inFeed) {
                    dispatch(completeFeedQuestionHide({questionId}));
                } else {
                    dispatch(completePostQuestionHide({questionId}));
                    dispatch(completeFeedQuestionHide({questionId}));
                }
                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionSave = (title, message, groupId, type, anonymous, nsfw, isActive, images=[], linkData=null, gifId='') => {    
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionSave']['url'],            
            method: CONFIG['api']['questionSave']['method']
        },
        {title, message, groupId, ...(type && {type}), anonymous, nsfw, isActive, images, ...(linkData && {link_data:linkData}), ...(gifId.length && {gifId:gifId})}
    );
    return (dispatch, getState) => {
        dispatch(startCompose());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeCompose());
                if(xhrResponse.data.response && xhrResponse.data.response.feed){
                    dispatch(completePostFetch({response: xhrResponse.data.response.feed[0]}));
                }
                else{
                    dispatch(showInvalidMessage({message: xhrResponse.data.message}))
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionEdit = (questionId, message, title='', nsfw, images=[], linkData=null, gifId='',) => {    
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['questionEdit']['url'],            
            method: CONFIG['api']['questionEdit']['method']
        },
        {questionId, message, ...(!!title.length && {title}), ...(!!images.length && {images}), nsfw, ...(linkData && {link_data:linkData}), ...(gifId.length && {gifId:gifId})}
    );
    return (dispatch, getState) => {
        dispatch(startPostQuestionEdit());
        dispatch(startFeedQuestionEdit());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status){
                    const response = xhrResponse.data;
                    response.questionId = questionId;
                    dispatch(completePostQuestionEdit(response));
                    dispatch(completeFeedQuestionEdit(response));
                } else {
                    dispatch(showInvalidMessage({message: xhrResponse.data.message}));
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyEdit = (questionId, replyIndex, answerId, message, image) => {    
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['replyEdit']['url'],            
            method: CONFIG['api']['replyEdit']['method']
        },
        {answerId, message, ...(image && {image})}
    );
    return (dispatch, getState) => {
        dispatch(startPostReplyEdit());
        dispatch(startFeedReplyEdit());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status) {
                    const response = xhrResponse.data;
                    response.questionId = questionId;
                    response.replyIndex = replyIndex;
                    response.replyId = answerId;
                    dispatch(completePostReplyEdit(response));
                    dispatch(completeFeedReplyEdit(response));
                } else{
                    dispatch(showInvalidMessage({message: xhrResponse.data.message}))
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const questionDelete = (questionId, inFeed) => {
    const params = constructParams(
        {
            url: CONFIG['api']['questionDelete']['url'],            
            method: CONFIG['api']['questionDelete']['method']
        },
        {questionId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startFeedQuestionDelete());
        } else {
            dispatch(startPostQuestionDelete());
            dispatch(startFeedQuestionDelete());
        }        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.questionId = questionId;
                dispatch(completePostQuestionDelete(response));
                dispatch(completeFeedQuestionDelete(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyDelete = (questionId, replyIndex, answerId, inFeed) => {
    const params = constructParams(
        {
            url: CONFIG['api']['replyDelete']['url'],            
            method: CONFIG['api']['replyDelete']['method']
        },
        {answerId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startFeedReplyDelete());
        } else {
            dispatch(startPostReplyDelete());
            dispatch(startFeedReplyDelete());
        }        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.questionId = questionId;
                response.replyIndex = replyIndex;
                response.replyId = answerId;
                dispatch(completePostReplyDelete(response));
                dispatch(completeFeedReplyDelete(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const replyCommentDelete = (questionId, replyId, replyIndex, commentId, inFeed) => {
    const params = constructParams(
        {
            url: CONFIG['api']['replyCommentDelete']['url'],            
            method: CONFIG['api']['replyCommentDelete']['method']
        },
        {commentId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startFeedReplyCommentDelete());
        } else {
            dispatch(startPostReplyCommentDelete());
            dispatch(startFeedReplyCommentDelete());
        }        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.questionId = questionId;
                response.replyIndex = replyIndex;
                response.replyId = replyId;
                response.commentId = commentId;
                dispatch(completePostReplyCommentDelete(response));
                dispatch(completeFeedReplyCommentDelete(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const repliesFetch = (questionId, page, replySorting = '', isReset = false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['repliesFetch']['url'],            
            method: CONFIG['api']['repliesFetch']['method']
        },
        {questionId, page, ...(replySorting && {replySorting})}
    );
    return (dispatch, getState) => {
        dispatch(startPostRepliesFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePostRepliesFetch({response: xhrResponse.data.response, isReset}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const commentsFetch = (replyId, page) => {
    const params = constructParams(
        {
            url: CONFIG['api']['commentsFetch']['url'],            
            method: CONFIG['api']['commentsFetch']['method']
        },
        {replyId, page}
    );
    return (dispatch, getState) => {
        // dispatch(startPostCommentsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.replyId = replyId;
                dispatch(completePostCommentsFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const boothFetch = (type, page, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['feed']['url'],
            method: CONFIG['api']['feed']['method']
        },
        { page: page, type: type, ...(filter && {filter}) }
    );
    return (dispatch, getState) => {
        dispatch(startBoothFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && !xhrResponse.data.error) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = type;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;           
                dispatch(completeBoothFetch(response));   
            } else {
                //unauthenticated
                dispatch(resetBoothLoader());
                dispatch(resetMemoryLoader());
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const boothHashtagFetch = (hashtag, page, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['boothHashtagFetch']['url'],
            method: CONFIG['api']['boothHashtagFetch']['method']
        },
        { page: page, type: 'booth', hashtag: hashtag, ...(filter && {filter}) }
    );
    return (dispatch, getState) => {
        dispatch(startBoothFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && !xhrResponse.data.error) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = 'boothHash';
                response.hashtag = hashtag;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;
                dispatch(completeBoothFetch(response));   
            } else {
                //unauthenticated
                dispatch(resetBoothLoader());
                dispatch(resetMemoryLoader());
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryFetch = (id, commentId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['memoryFetch']['url'],            
            method: CONFIG['api']['memoryFetch']['method']
        },
        {boothId: id, ...(commentId && {commentId})}
    );
    return (dispatch, getState) => {
        dispatch(startMemoryFetch());        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then(xhrResponse => {
            if(xhrResponse.status == 200 && xhrResponse.data.status) {
                dispatch(completeMemoryFetch(xhrResponse.data));
                return xhrResponse.data;
            }
            else if(xhrResponse.data && xhrResponse.data.response && xhrResponse.data.response.block_user && xhrResponse.data.response.block_user.user_id && xhrResponse.data.response.block_user.anonymous_status === 0 && getState().userReducer.id && xhrResponse.data.response.block_user.user_id === getState().userReducer.id){
                return xhrResponse.data.response;
            }
            else {
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryCommentSave = (boothId, message) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['memoryCommentSave']['url'],            
            method: CONFIG['api']['memoryCommentSave']['method']
        },
        {boothId, message}
    );
    return (dispatch, getState) => {
        dispatch(startMemoryCommentSave());
        dispatch(startBoothCommentSave());
        //request
        // axiosClient.defaults.headers.post['content-type'] = 'multipart/form-data';
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status){
                    const response = xhrResponse.data;
                    response.boothId = boothId;
                    dispatch(completeMemoryCommentSave(response));
                    dispatch(completeBoothCommentSave(response));
                }else {
                    dispatch(showInvalidMemoryMessage({message: xhrResponse.data.message}));
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryCaptionEdit = (boothId, message) => {    
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['memoryCaptionEdit']['url'],            
            method: CONFIG['api']['memoryCaptionEdit']['method']
        },
        {boothId, message}
    );
    return (dispatch, getState) => {
        dispatch(startMemoryCaptionEdit());
        dispatch(startBoothCaptionEdit());
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status){
                    const response = xhrResponse.data;
                    response.boothId = boothId;
                    dispatch(completeMemoryCaptionEdit(response));
                    dispatch(completeBoothCaptionEdit(response));
                } else{
                    dispatch(showInvalidMemoryMessage({message: xhrResponse.data.message}));
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryDelete = (boothId, inFeed) => {
    const params = constructParams(
        {
            url: CONFIG['api']['memoryDelete']['url'],            
            method: CONFIG['api']['memoryDelete']['method']
        },
        {boothId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startBoothDelete());
        } else {
            dispatch(startMemoryDelete());
            dispatch(startBoothDelete());
        }        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.boothId = boothId;
                dispatch(completeMemoryDelete(response));
                dispatch(completeBoothDelete(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryCommentDelete = (boothId, commentId, inFeed) => {
    const params = constructParams(
        {
            url: CONFIG['api']['memoryCommentDelete']['url'],            
            method: CONFIG['api']['memoryCommentDelete']['method']
        },
        {commentId}
    );
    return (dispatch, getState) => {
        if(inFeed) {
            dispatch(startBoothCommentDelete());
        } else {
            dispatch(startMemoryCommentDelete());
            dispatch(startBoothCommentDelete());
        }        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.boothId = boothId;
                response.commentId = commentId;
                dispatch(completeMemoryCommentDelete(response));
                dispatch(completeBoothCommentDelete(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryLike = (cardIndex, inFeed, boothId) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['memoryLike']['url'],            
            method: CONFIG['api']['memoryLike']['method']
        },
        {boothId}
    );
    return (dispatch, getState) => {
        const index = findBoothIndexById(boothId, getState().boothReducer.boothList);
        const boothLikeCount = index < 0 ? 0 : getState().boothReducer.boothList[index] && getState().boothReducer.boothList[index].likes.count;
        const memoryLikeCount = getState().memoryReducer.data.likes && getState().memoryReducer.data.likes.count;
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const liked = true;
                if(inFeed) {
                    dispatch(completeBoothLike({boothId, boothLikeCount, liked}));
                } else {
                    dispatch(completeBoothLike({boothId, boothLikeCount, liked}));
                    dispatch(completeMemoryLike({boothId, memoryLikeCount, liked}));
                }                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryUnlike = (cardIndex, inFeed, boothId) => {
    // console.log(image);
    const params = constructParams(
        {
            url: CONFIG['api']['memoryUnlike']['url'],            
            method: CONFIG['api']['memoryUnlike']['method']
        },
        {boothId}
    );
    return (dispatch, getState) => {
        const index = findBoothIndexById(boothId, getState().boothReducer.boothList);
        const boothLikeCount = index < 0 ? 0 : getState().boothReducer.boothList[index] && getState().boothReducer.boothList[index].likes.count;
        const memoryLikeCount = getState().memoryReducer.data.likes && getState().memoryReducer.data.likes.count;        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const liked = false;
                if(inFeed) {
                    dispatch(completeBoothUnlike({boothId, boothLikeCount, liked}));
                } else {
                    dispatch(completeBoothUnlike({boothId, boothLikeCount, liked}));
                    dispatch(completeMemoryUnlike({boothId, memoryLikeCount, liked}));
                }                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const memoryCommentsFetch = (boothId, page) => {
    const params = constructParams(
        {
            url: CONFIG['api']['memoryCommentsFetch']['url'],            
            method: CONFIG['api']['memoryCommentsFetch']['method']
        },
        {boothId, page}
    );
    return (dispatch, getState) => {
        dispatch(startMemoryCommentsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.boothId = boothId;
                dispatch(completeMemoryCommentsFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicFeedFetch = (type, page, groupSlug, groupId, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicFeedFetch']['url'],
            method: CONFIG['api']['topicFeedFetch']['method']
        },
        { type, page, ...(groupId && {groupId}), ...(groupSlug && {groupSlug}), ...(filter && {filter}) }
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = type;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;                
                dispatch(completeFeedFetch(response));
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicListFetch = (default_group = 0) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicListFetch']['url'],
            method: CONFIG['api']['topicListFetch']['method']
        },
        {default_suggested_group: default_group}
    );
    return (dispatch, getState) => {
        dispatch(startTopicListFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;     
                dispatch(completeTopicListFetch(response));  
                return response; 
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicFollow = (groupId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicFollow']['url'],
            method: CONFIG['api']['topicFollow']['method']
        },
        {group_id: groupId}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {}
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicUnfollow = (groupId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicUnfollow']['url'],
            method: CONFIG['api']['topicUnfollow']['method']
        },
        {group_id: groupId}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {}
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicRelatedListFetch = (groupId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicRelatedListFetch']['url'],
            method: CONFIG['api']['topicRelatedListFetch']['method']
        },
        {...(groupId && {groupId})}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                dispatch(completeTopicRelatedListFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicSuggestionsFetch = (question) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicSuggestionsFetch']['url'],            
            method: CONFIG['api']['topicSuggestionsFetch']['method']
        },
        {question}
    );
    return (dispatch, getState) => {
        dispatch(startTopicSuggestionsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                dispatch(completeTopicSuggestionsFetch(response));
                return response
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorProfileUpdate = (data) => {
    const { langCode, fullname, gender, country, tagline, image, dueDate, kidsInfo, email, address, dob, phone, education, working_status, marital_status, pincode } = data;
    const params = constructParams(
        {
            url: CONFIG['api']['authorProfileUpdate']['url'],
            method: CONFIG['api']['authorProfileUpdate']['method']
        },
        {...((image !== null) && {image}), tagline, language: langCode, fullname, gender, locationCode: country, dueDate, kidsInfo, secondaryEmail: email, address, phone, educationLevel: education, maritalStatus: marital_status, birthDate: dob, workingStatus: working_status, areaCode: pincode}
    );
    return (dispatch, getState) => {
        dispatch(startAuthorProfileUpdate());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                dispatch(completeAuthorProfileUpdate(xhrResponse.data.response));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorProfileAndPostsFetch = (type, page, slug, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorProfileAndPostsFetch']['url'],
            method: CONFIG['api']['authorProfileAndPostsFetch']['method']
        },
        {slug}
    );
    return (dispatch, getState) => {
        dispatch(startFeedFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = type;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;                
                dispatch(completeFeedFetch(response));
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorFeedFetch = (type, page, authorId, shouldReset, accessedFrom, filter=null, ifServerFilled=false) => {
    let apiMethod = '';
    switch(type) {
        case 'posts':
        apiMethod = 'authorQuestionsFetch';
        break;
        case 'replies':
        apiMethod = 'authorRepliesFetch';
        break;
        case 'booth':
        apiMethod = 'authorMemoriesFetch';
        break;
        default:
        apiMethod = '';
    }
    const params = constructParams(
        {
            url: CONFIG['api'][apiMethod]['url'],
            method: CONFIG['api'][apiMethod]['method']
        },
        {whichUser: authorId, page}
    );
    return (dispatch, getState) => {
        if(type == 'booth') {
            dispatch(startBoothFetch());
        } else {
            dispatch(startFeedFetch());    
        }
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.currentFeed = type;
                response.shouldReset = shouldReset;
                response.accessedFrom = accessedFrom;
                response.filter = filter;
                response.ifServerFilled = ifServerFilled;
                if(type == 'booth') {
                    dispatch(completeBoothFetch(response));
                } else {
                    dispatch(completeFeedFetch(response));
                } 
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorFollowAction = (userId, actionId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorFollowAction']['url'],
            method: CONFIG['api']['authorFollowAction']['method']
        },
        {followingId: userId, follow: actionId}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {}
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorFollowersFetch = (whichUser, page, shouldReset=true) => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorFollowersFetch']['url'],
            method: CONFIG['api']['authorFollowersFetch']['method']
        },
        {whichUser, page}
    );
    return (dispatch, getState) => {
        dispatch(startAuthorFollowersFetch(page === 1 ? [] : getState().userReducer['followers']));
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.shouldReset = shouldReset;
                dispatch(completeAuthorFollowersFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorFollowingFetch = (whichUser, page, shouldReset=true) => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorFollowingFetch']['url'],
            method: CONFIG['api']['authorFollowingFetch']['method']
        },
        {whichUser, page}
    );
    return (dispatch, getState) => {
        dispatch(startAuthorFollowingFetch(page === 1 ? [] : getState().userReducer['following']));
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.shouldReset = shouldReset;
                dispatch(completeAuthorFollowingFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const authorRolesFetch = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorRolesFetch']['url'],
            method: CONFIG['api']['authorRolesFetch']['method']
        },
        {}
    );
    return (dispatch, getState) => {
        dispatch(startAuthorRolesFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data){
                dispatch(completeAuthorRolesFetch(xhrResponse.data.response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const login = (email, password, source, authType) => {
    const params = constructParams(
        {
            url: CONFIG['api']['login']['url'],
            method: CONFIG['api']['login']['method']
        },
        { auth_type: authType, email, password, source }
    );

    params.headers = {
        'sourceapp': 'beyeu'
    };

    return (dispatch, getState) => {
        params.data['device_type'] = getState().appReducer.deviceType;
        dispatch(startUserAuthentication());
        return buildAxios(dispatch, getState, 'apiBaseV3').request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                if(xhrResponse.data.status) {
                    dispatch(completeUserAuthentication(xhrResponse.data)); 
                } else {
                    dispatch(resetUserLoader());
                }
            }
            return xhrResponse.data;
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const forgotPassword = (email) => {
    const params = constructParams(
        {
            url: CONFIG['api']['forgotPassword']['url'],
            method: CONFIG['api']['forgotPassword']['method']
        },
        {email}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeForgotPassword(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const setUserLoggedIn = (data) => {
    const params = constructParams(
        {
            url: 'user/login',
            method: 'post'
        },
        {data}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            console.log(error);
        });
    }
};

export const updateUserSession = (data) => {
    const params = constructParams(
        {
            url: 'user/update',
            method: 'post'
        },
        {data}
    );
    return (dispatch, getState) => {
        return buildAxiosSite(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
                // dispatch(completeUserAuthentication(xhrResponse.data));
            }
        })
        .catch((error) => {
            return error;
            console.log(error);
        });
    }
};

export const emailCheck = (email) => {
    const params = constructParams(
        {
            url: CONFIG['api']['emailCheck']['url'],            
            method: CONFIG['api']['emailCheck']['method']
        },
        {email}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                dispatch(setAppParams({emailCheck: response.response.email_count}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const linkPreviewFetch = (q) => {
    const params = constructParams(
        {
            url: CONFIG['api']['linkPreviewFetch']['url'],            
            method: CONFIG['api']['linkPreviewFetch']['method']
        },
        {q}
    );
    return (dispatch, getState) => {
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                const response = xhrResponse.data;
                if(response.response.type) dispatch(completeLinkPreviewFetch(response));
            }else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const search = (q, page, type, shouldReset, suggest=0, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['search']['url'],            
            method: CONFIG['api']['search']['method']
        },
        {q, page, type, suggest}
    );
    return (dispatch, getState) => {
        dispatch(startSearch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.q = q;
                response.page = page;
                response.type = type;
                response.shouldReset = shouldReset;
                response.ifServerFilled = ifServerFilled;
                if(type == 'top') {
                    dispatch(completeSearchTop(response));
                } else {
                    dispatch(completeSearch(response));
                }
                return response                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const searchV2 = (q, page, type, shouldReset, suggest=0, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['searchV2']['url'],            
            method: CONFIG['api']['searchV2']['method']
        },
        {q, page, type, suggest}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.q = q;
                response.page = page;
                response.type = type;
                response.shouldReset = shouldReset;
                response.ifServerFilled = ifServerFilled;
                return response                
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const onboardingInfoSave = (args) => {
    const params = constructParams(
        {
            url: CONFIG['api']['onboardingInfoSave']['url'],            
            method: CONFIG['api']['onboardingInfoSave']['method']
        },
        args
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicsSelectedRegister = (groups) => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicsSelectedRegister']['url'],            
            method: CONFIG['api']['topicsSelectedRegister']['method']
        },
        {groups}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                if(xhrResponse.data.response && xhrResponse.data.response.tracker_info){
                    const data = xhrResponse.data.response.tracker_info;
                    dispatch(completeUserOnboarding(data));
                }
                else{
                    dispatch(completeUserOnboarding());
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const notificationsFetch = (lastNotifId = 0, isReset = true) => {
    const params = constructParams(
        {
            url: CONFIG['api']['notificationsFetch']['url'],            
            method: CONFIG['api']['notificationsFetch']['method']
        },
        {lastNotifId}
    );
    return (dispatch, getState) => {
        dispatch(startNotificationsFetch());        
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeNotificationsFetch({response: xhrResponse.data.response, isReset}));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const privacyContentFetch = (ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['privacyContentFetch']['url'],            
            method: CONFIG['api']['privacyContentFetch']['method']
        },
        {}
    );
    return (dispatch, getState) => {
        dispatch(startStaticContentFetch());       
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.ifServerFilled = ifServerFilled;
                dispatch(completeStaticContentFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const trackerCalendarFetch = (type=null) => {
    const params = constructParams(
        {
            url: CONFIG['api']['trackerCalendarFetch']['url'],            
            method: CONFIG['api']['trackerCalendarFetch']['method']
        },
        {...(type && {type})}
    );
    return (dispatch, getState) => {
        dispatch(startTrackerCalendarFetch());       
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.status) {
                dispatch(completeTrackerCalendarFetch(xhrResponse.data));
                return xhrResponse.data
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const userFollowSuggestions = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['userFollowSuggestions']['url'],            
            method: CONFIG['api']['userFollowSuggestions']['method']
        },
        {}
    );
    return (dispatch, getState) => {
        dispatch(startUserFollowSuggestionsFetch());       
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeUserFollowSuggestionsFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const topicTrendingListFetch = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['topicTrendingListFetch']['url'],            
            method: CONFIG['api']['topicTrendingListFetch']['method']
        },
        {}
    );
    return (dispatch, getState) => {       
        //request
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeTopicTrendingListFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const feedbackSave = (username, email, message) => {
    const params = constructParams(
        {
            url: CONFIG['api']['feedbackSave']['url'],            
            method: CONFIG['api']['feedbackSave']['method']
        },
        {username, email, message}
    );
    return (dispatch, getState) => {       
        dispatch(startFeedbackSave());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeFeedbackSave());
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const trackerPregnancyDetailsFetch = (week) => {
    const params = constructParams(
        {
            url: CONFIG['api']['trackerPregnancyDetailsFetch']['url'],            
            method: CONFIG['api']['trackerPregnancyDetailsFetch']['method']
        },
        {week}
    );
    return (dispatch, getState) => {       
        dispatch(startTrackerPregnancyDetailsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.week = xhrResponse.data.status ? week : null;
                dispatch(completeTrackerPregnancyDetailsFetch(response));
                return response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const trackerBabyDetailsFetch = (month) => {
    const params = constructParams(
        {
            url: CONFIG['api']['trackerBabyDetailsFetch']['url'],            
            method: CONFIG['api']['trackerBabyDetailsFetch']['method']
        },
        {month}
    );
    return (dispatch, getState) => {       
        dispatch(startTrackerBabyDetailsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.month = xhrResponse.data.status ? month : null;
                dispatch(completeTrackerBabyDetailsFetch(response));
                return response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const trackerTodayFetch = (month) => {
    const params = constructParams(
        {
            url: CONFIG['api']['trackerTodayFetch']['url'],            
            method: CONFIG['api']['trackerTodayFetch']['method']
        },
        {month}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeTrackerTodaySidebar(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const tapArticlesFetch = (type) => {
    const params = constructParams(
        {
            url: CONFIG['api']['tapArticlesFetch']['url'],            
            method: CONFIG['api']['tapArticlesFetch']['method']
        },
        {type}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeTapArticlesSidebar(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const contestListFetch = (page, shouldReset, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['contestListFetch']['url'],            
            method: CONFIG['api']['contestListFetch']['method']
        },
        {page}
    );
    return (dispatch, getState) => {
        dispatch(startContestListFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.shouldReset = shouldReset;
                response.ifServerFilled = ifServerFilled;
                dispatch(completeContestListFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const contestDetailsFetch = (contestId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['contestDetailsFetch']['url'],            
            method: CONFIG['api']['contestDetailsFetch']['method']
        },
        {contestId}
    );
    return (dispatch, getState) => {
        dispatch(startContestDetailsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeContestDetailsFetch(xhrResponse.data));
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const contestMoreFetch = (contestId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['contestMoreFetch']['url'],            
            method: CONFIG['api']['contestMoreFetch']['method']
        },
        {contestId}
    );
    return (dispatch, getState) => {
        dispatch(startContestDetailsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeContestMoreListFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const sitemapFetch = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['sitemapFetch']['url'],            
            method: CONFIG['api']['sitemapFetch']['method']
        },
        {}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeSitemapFetch(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const passwordResetVerifyToken = (token) => {
    const params = constructParams(
        {
            url: CONFIG['api']['passwordResetVerifyToken']['url'],            
            method: CONFIG['api']['passwordResetVerifyToken']['method']
        },
        {token}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePasswordResetVerifyToken(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const passwordReset = (token, password) => {
    const params = constructParams(
        {
            url: CONFIG['api']['passwordReset']['url'],            
            method: CONFIG['api']['passwordReset']['method']
        },
        {token, password}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completePasswordReset(xhrResponse.data));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const unsubscribe = (q, type, newsletterType) => {
    const params = constructParams(
        {
            url: CONFIG['api']['unsubscribe']['url'],            
            method: CONFIG['api']['unsubscribe']['method']
        },
        {q, type, newsletter_type: newsletterType}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const rewardsListFetch = (page, shouldReset, ifServerFilled=false) => {
    const params = constructParams(
        {
            url: CONFIG['api']['rewardsListFetch']['url'],            
            method: CONFIG['api']['rewardsListFetch']['method']
        },
        {page}
    );
    return (dispatch, getState) => {
        dispatch(startRewardsListFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                const response = xhrResponse.data;
                response.page = page;
                response.shouldReset = shouldReset;
                response.ifServerFilled = ifServerFilled;
                dispatch(completeRewardsListFetch(response));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const rewardDetailsFetch = (rewardId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['rewardDetailsFetch']['url'],            
            method: CONFIG['api']['rewardDetailsFetch']['method']
        },
        {rewardId}
    );
    return (dispatch, getState) => {
        dispatch(startRewardDetailsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                dispatch(completeRewardDetailsFetch(xhrResponse.data));
                return xhrResponse.data;
            } else {
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const covidLandingPageDataFetch = (country) => {
    return (dispatch, getState) => {
        const params = constructParams(
            {
                url: CONFIG['api']['covidLandingPageFetch']['url']+country,            
                method: CONFIG['api']['covidLandingPageFetch']['method']
            },
            {}
        );
        dispatch(startCovidLandingPageFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(typeof xhrResponse.data != 'undefined') {
                if(xhrResponse.status == 200) {
                    dispatch(completeCovidLandingPageFetch(xhrResponse.data));
                    return xhrResponse.data;
                }
                else {
                    return null;
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const covidLoadMoreDataFetch = (page, country) => {
    
    // console.log(params, "PARAMS");
    return (dispatch, getState) => {
        const params = constructParams(
            {
                url: CONFIG['api']['CovidLoadMoreData']['url']+country,            
                method: CONFIG['api']['CovidLoadMoreData']['method']
            },
            {page}
        );
        dispatch(startCovidLoadMoreData());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
                if(xhrResponse.status == 200) {
                    dispatch(completeCovidLoadMoreData(xhrResponse.data));
                    return xhrResponse.data;
                }
                else {
                    return null;
                }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const covidCategoryQnADataFetch = (country, category, page) => {
    return (dispatch, getState) => {
        const params = constructParams(
            {
                url: CONFIG['api']['CovidCategoryQnAData']['url']+country,            
                method: CONFIG['api']['CovidCategoryQnAData']['method']
            },
            {category, page}
        );
        dispatch(startCovidCotegoryQnAFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
                if(xhrResponse.status == 200) {
                    if( page == 1)
                    {
                        dispatch(completeCovidCotegoryQnAFetch(xhrResponse.data.response.data[0]));
                        return xhrResponse.data.response.data[0];
                    }
                    else {
                        dispatch(completeAddCovidCotegoryQnAFetch(xhrResponse.data.response.data[0].qna));
                        return xhrResponse.data.response.data[0].qna;
                    }
                }
                else {
                    return null;
                }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};


export const covidAnswerFetch = (country, id) => {
    return (dispatch, getState) => {
        const params = constructParams(
            {
                url: CONFIG['api']['covidAnswerFetch']['url']+country,            
                method: CONFIG['api']['covidAnswerFetch']['method']
            },
            {id}
        );
        dispatch(startCovidAnswerFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
                if(xhrResponse.status == 200) {
                    dispatch(completeCovidAnswerFetch(xhrResponse.data));
                    return xhrResponse.data;
                }
                else {
                    return null;
                }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const foodLandingPageDataFetch = (page=1) => {
    const params = constructParams(
        {
            url: CONFIG['api']['foodLandingPage']['url'],            
            method: CONFIG['api']['foodLandingPage']['method']
        },
        {page}
    );
    return (dispatch, getState) => {
        dispatch(startFoodLandingDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.response){
                dispatch(completeFoodLandingDataFetch(xhrResponse.data.response));

                return xhrResponse.data.response;
            }
            else{
                return null
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const foodListingDataFetch = (page=1, slug) => {
    const params = constructParams(
        {
            url: CONFIG['api']['foodListing']['url'],            
            method: CONFIG['api']['foodListing']['method']
        },
        {page, slug}
    );
    return (dispatch, getState) => {
        dispatch(startFoodListingDataFetch(page == 1 ? {} : getState().foodReducer.listing));

        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {

            if(xhrResponse.status == 200 && xhrResponse.data && xhrResponse.data.response) {
                const response = xhrResponse.data.response;
                response.page = page;
                if(page === 1){
                    dispatch(completeFoodListingDataFetch(response));
                    return xhrResponse.data.response;
                } else{
                    response.foods = getState().foodReducer.listing.foods.concat(response.foods);

                    //If non empty data is coming in response.foods
                    if(response.foods.length !== getState().foodReducer.listing.foods.length) {
                        dispatch(completeFoodListingDataFetch(response));
                    } else {
                        //Set loader false if no more data
                        dispatch(resetFoodLoader());
                    }
                    return response;
                }
            }
            else{
                return null
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const foodDetailDataFetch = (slug, itemId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['foodDetail']['url'],            
            method: CONFIG['api']['foodDetail']['method']
        },
        {slug, itemId}
    );
    return (dispatch, getState) => {
        dispatch(startFoodDetailDataFetch({}));
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
                if(xhrResponse.status == 200) {
                    if(xhrResponse.data.status && xhrResponse.data.response){
                        dispatch(completeFoodDetailDataFetch(xhrResponse.data.response));
                        return xhrResponse.data.response;
                    }
                }
                else{
                    return null
                }
            })
        .catch((error) => {
            console.log(error);
        });
    }
};


export const recipeDetailDataFetch = (id) => {
    const params = constructParams(
        {
            url: CONFIG['api']['recipeDetail']['url'],
            method: CONFIG['api']['recipeDetail']['method']
        },
        {recipeId: id}
    )
    return (dispatch, getState) => {
        dispatch(startRecipePageDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeRecipeDetailDataFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const recipesListingDataFetch = (page = 1, type, key) => {
    const params = constructParams(
        {
            url: CONFIG['api']['recipesListing']['url'],
            method: CONFIG['api']['recipesListing']['method']
        },
        {page, type, key}
    )
    return (dispatch, getState) => {
        dispatch(startRecipePageDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                const response = xhrResponse.data.response;
               response.page = page
               if(page == 1){
                   dispatch(completeRecipesListingDataFetch(response));
                   return xhrResponse.data.response;
               }
               else{
                    if(response.recipes){
                        response.recipes = getState().recipeReducer.listing.recipes.concat(response.recipes);
                    }
                    else{
                        response.recipes = getState().recipeReducer.listing.recipes.concat([]);
                    }
                    dispatch(completeRecipesListingDataFetch(response));
                    return response;
               }
            }
            else{
                return null
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const foodDetailDataFetchAmp = (slug, itemId) => {
    const params = constructParams(
        {
            url: CONFIG['api']['foodDetail']['url'],            
            method: CONFIG['api']['foodDetail']['method']
        },
        {slug, itemId}
    );
    return buildAxiosBase()
        .request(params)
        .then((xhrResponse) => {
            return xhrResponse;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const recipesCategoryDataFetch = (type) => {
    const params = constructParams(
        {
            url: CONFIG['api']['recipesCategory']['url'],
            method: CONFIG['api']['recipesCategory']['method']
        },
        {type}
    )
    return (dispatch, getState) => {
        dispatch(startRecipePageDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
               dispatch(completeRecipesCategoryDataFetch(xhrResponse.data.response));
               return xhrResponse.data.response;
            }
            else{
                dispatch(completeRecipePageDataFetch());
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const recipeDetailDataFetchAmp = (id) => {
    const params = constructParams(
        {
            url: CONFIG['api']['recipeDetail']['url'],
            method: CONFIG['api']['recipeDetail']['method']
        },
        {recipeId: id}
    )
    return buildAxiosBase()
        .request(params)
        .then((xhrResponse) => {
            return xhrResponse;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const recipesLandingDataFetch = () => {
    const params = constructParams(
        {
            url: CONFIG['api']['recipes']['url'],
            method: CONFIG['api']['recipes']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        dispatch(startRecipePageDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeRecipesLandingDataFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const fetchHeaderMenu = (country = defaultCountry) => {
    const fetchURL = isBeYeuTemplate ? `${getTapAPIBase(process.env.REACT_APP_ENV)}beyeu/boot/navigation` : `${getTapAPIBase(process.env.REACT_APP_ENV)}${country}/boot/navigation`
    return (dispatch) => {
        return axios.get(fetchURL)
        .then((xhrResponse) => {
            if(xhrResponse.status === 200 && xhrResponse.data.status_code === 200 && xhrResponse.data.data){
                dispatch(completeHeaderMenuFetch(xhrResponse.data.data.header_menu));
                return xhrResponse.data.data.header_menu;
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }
}

export const fetchAmpHeaderMenu = (country = defaultCountry) => {
    const fetchURL = isBeYeuTemplate ? `${getTapAPIBase(process.env.REACT_APP_ENV)}beyeu/boot/navigation` : `${getTapAPIBase(process.env.REACT_APP_ENV)}${country}/boot/navigation`
    return axios.get(fetchURL)
    .then((xhrResponse) => {
        if(xhrResponse.status === 200 && xhrResponse.data.status_code === 200 && xhrResponse.data.data){
            return xhrResponse.data.data.header_menu;
        }
    })
    .catch((error) => {
        console.log(error)
    });
}

/*To create short link 
https://api.theasianparent.com/api/v1/sg/dyanmiclink/custom/?url=https://community.theasianparent.com/q/when-baby-drink-yakult-vitagen/19121&source=test */

export const createShortLink = (url="", source="", country = defaultCountry) => {
    const params = {
        url: (CONFIG['siteUrl'].slice(0, -1)+url),
        source: source
    };
    // bugsnagClientNotify(country, url, source, "country info", "createShortLink");
    return axios.get(`https://api.theasianparent.com/api/v1/${country}/dyanmiclink/custom/`, {params})
    .then((xhrResponse) => {
        if(xhrResponse.data && xhrResponse.data.status_code == 200 && xhrResponse.data.data)
        {
            const response = xhrResponse.data;

            return {
                short_link: response.data.short_link,
                preview_link: response.data.preview_link,
            }   
        }
        else {
            return {};
        }
    })
    .catch((error) => {
        console.log(error);
        return {};
    });
}

export const sitemapHomeDataFetch = (country = '192') => {
    const params = constructParams(
        {
            url: `${CONFIG['api']['sitemapBase']['url']}${country}/get-years`,
            method: CONFIG['api']['sitemapBase']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        dispatch(startSitemapHomeDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeSitemapHomeDataFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const sitemapArticleByYearDataFetch = (year, country = '192') => {
    const params = constructParams(
        {
            url: `${CONFIG['api']['sitemapBase']['url']}${country}/get-weeks`,
            method: CONFIG['api']['sitemapBase']['method']
        },
        {year}
    )
    return (dispatch, getState) => {
        dispatch(startSitemapArticleByYearDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeSitemapArticleByYearDataFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const sitemapArticleByWeekDataFetch = (year, week, page = 1, country = '192') => {
    const params = constructParams(
        {
            url: `${CONFIG['api']['sitemapBase']['url']}${country}/get-topics`,
            method: CONFIG['api']['sitemapBase']['method']
        },
        {year, week, page}
    )
    return (dispatch, getState) => {
        dispatch(startSitemapArticleByWeekDataFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeSitemapArticleByWeekDataFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);  
        });
    }
}

export const dfpAdUnitsFetch = (/*country = 'sg'*/) => {
    return (dispatch) => {
        return axios.get(`https://sg.theasianparent.com/tm-dfp-config/${process.env.REACT_APP_ENV == 'production' ? 'community' : 'dev'}/`)
        .then((xhrResponse) => {
            // console.log(xhrResponse);
            dispatch(completeDfpAdUnitsFetch(xhrResponse.data));
            return xhrResponse.data;
        })
        .catch((error) => {
            console.log(error);   
        });
    }
}


export const contentHubDataFetch = (campaign_slug, activity_type_slug,page = 1, type, isPollsQa, shouldReset) => { 
    let params; 
    if(isPollsQa){
        params = constructParams(
            {
                url: `${CONFIG['api']['contentHub']['url']}?campaign_slug=${campaign_slug}&activity_type_slug=${activity_type_slug}&page=${page}&type=${type}`,
                method: CONFIG['api']['contentHub']['method']
            },
            {}
        )
    }
    else{
        params = constructParams(
            {
                url: `${CONFIG['api']['contentHub']['url']}?campaign_slug=${campaign_slug}&activity_type_slug=${activity_type_slug}&page=${page}`,
                method: CONFIG['api']['contentHub']['method']
            },
            {}
        )
    }
    
    return (dispatch, getState) => {
        dispatch(startContentHubDataFetch());
        dispatch(startContentHubFeedFetch());
        return buildAxios(dispatch, getState,'msApiBase').request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeContentHubFetch(xhrResponse.data.response));
                const data = xhrResponse.data.response
                if(isPollsQa && data.activites && data.activites[data.activites.findIndex(x => x.activity_type_slug.toLocaleLowerCase() === "poll")].card_data){
                    const response = data.activites[data.activites.findIndex(x => x.activity_type_slug.toLocaleLowerCase() === "poll")].card_data[0].feed;
                    response.page = page;
                    response.shouldReset = shouldReset;
                    dispatch(completeContentHubFeedFetch(response));
                }else if(isPollsQa && data.activites && data.activites[data.activites.findIndex(x => x.activity_type_slug.toLocaleLowerCase() === "qna")].card_data){
                    const response = data.activites[data.activites.findIndex(x => x.activity_type_slug.toLocaleLowerCase() === "qna")].card_data[0].feed;
                    response.page = page;
                    response.shouldReset = shouldReset;
                    dispatch(completeContentHubFeedFetch(response));
                }
                return xhrResponse.data.response;
            }
            else{
                return 'error';
            }
        })
        .catch((error) => {
            console.log(error);
            return 'error'
        });
    }
}

export const contentHubArticleFetchFiler = (campaign_slug, activity_type_slug,article_filter_slug,page) => {
    const params = constructParams(
        {
            url: `${CONFIG['api']['contentHub']['url']}?campaign_slug=${campaign_slug}&activity_type_slug=${activity_type_slug}&article_filter_slug=${article_filter_slug}&page=${page}`,
            method: CONFIG['api']['contentHub']['method']
        },
        {}
    )
    
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState,'msApiBase').request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const contentHubDetailPageDataFetch = (campaign_slug, activity_type_slug, campaign_activity_slug, page = 1) => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['contentHubDetailPage']['url']}?campaign_slug=${campaign_slug}&activity_type_slug=${activity_type_slug}&campaign_activity_slug=${campaign_activity_slug}&page=${page}`,
            method: CONFIG['api']['contentHubDetailPage']['method']
        },
        {}
    )
    
    return (dispatch, getState) => {
        dispatch(startContentHubDetailPageDataFetch());
        return buildAxios(dispatch, getState,'msApiBase').request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                dispatch(completeContentHubDetailPageFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const fetchAuthorArticles = (country, userName) => {  
    return (dispatch, getState) => {
        const state = getState();

        const isSameAuthorRequest = state && state.userReducer && state.userReducer.slug === userName ? true : false;

        const customConfig = {
            // withCredentials: true,
        };

        if(state && state.userReducer.token) customConfig['headers'] = {'Authorization': 'Bearer ' + state.userReducer.token};
        const instance = axios.create(customConfig);
        dispatch(startAuthorArticleFetch());
        return instance.get(`${getTapAPIBase(process.env.REACT_APP_ENV)}${country}/author/${userName}?isSameAuthor=${isSameAuthorRequest}`)
        .then((xhrResponse) => {
            dispatch(completeAuthorArticleFetch(xhrResponse.data.data));
            return xhrResponse.data.data
        })
        .catch((error) => {
            return error;
        });
    }
}

export const trendingGifFetch = (next='') => {
    return () => {
        return axios.get(`https://g.tenor.com/v1/trending`, { params: {key: 'LIVDSRZULELA', limit: 20, locale: 'en_US', media_filter: 'minimal', ar_range: 'all', contentfilter: 'off', pos: `${next}`}})
        .then((xhrResponse) => {
            return xhrResponse;
        })
        .catch((error) => {
            console.log(error);   
        });
    }
}

export const searchGifFetch = (query, next='') => {
    return () => {
        return axios.get(`https://g.tenor.com/v1/search`, { params: {q: `${query}`, key: 'LIVDSRZULELA', limit: 20, locale: 'en_US', media_filter: 'minimal', ar_range: 'all', contentfilter: 'off', pos: `${next}`}})
        .then((xhrResponse) => {
            return xhrResponse;
        })
        .catch((error) => {
            console.log(error);   
        });
    }
}


export const similarQuestionsFetch = (page = 1, title='', content='') => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['similarQuestions']['url']}?page=${page}&title=${title}&message=${content}`,
            method: CONFIG['api']['similarQuestions']['method']
        },
        {}
    )
    
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}
export const trendingHashtagFetch = () => {    
    const params = constructParams(
        {
            url: CONFIG['api']['trendingHashtag']['url'],
            method: CONFIG['api']['trendingHashtag']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.status && xhrResponse.data.response){
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const suggestedHashtagFetch = (query='') => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['suggestedHashtag']['url']}?q=${query}`,
            method: CONFIG['api']['suggestedHashtag']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.response){
                return xhrResponse.data.response;
            }
            else{
                return null;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const userBlockListFetch = (page=1, userName) => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['userBlockList']['url']}?page=${page}`,
            method: CONFIG['api']['userBlockList']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        const state = getState();
        const isSameAuthorRequest = state && state.userReducer && state.userReducer.slug === userName ? true : false;
        if(isSameAuthorRequest){
            dispatch(startBlockUserListFetch());
            return buildAxios(dispatch, getState).request(params)
            .then((xhrResponse) => {
                if(xhrResponse.status == 200 && xhrResponse.data.response){
                    dispatch(completeBlockUserListFetch(xhrResponse.data.response));
                    return xhrResponse.data.response;
                }
                else{
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
}

export const getProfileFetch = (slug) => {
    const params = constructParams(
        {
            url: CONFIG['api']['authorProfileAndPostsFetch']['url'],
            method: CONFIG['api']['authorProfileAndPostsFetch']['method']
        },
        {slug}
    );
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200) {
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
};

export const userSettingsFetch = () => {    
    const params = constructParams(
        {
            url: CONFIG['api']['userSettings']['url'],
            method: CONFIG['api']['userSettings']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        dispatch(startUserSettingsFetch());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.response){
                dispatch(completeUserSettingsFetch(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const userSettingsUpdate = (setting_data_sharing) => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['userSettingsUpdate']['url']}?setting_data_sharing=${setting_data_sharing}`,
            method: CONFIG['api']['userSettingsUpdate']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        dispatch(startUserSettingsUpdate());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200){
                dispatch(completeUserSettingsUpdate());
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const configFetch = () => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['configFetch']['url']}`,
            method: CONFIG['api']['configFetch']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.response){
                dispatch(updateDataSharingStatus(xhrResponse.data.response))
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const dataSharingInfo = () => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['dataSharingInfo']['url']}`,
            method: CONFIG['api']['dataSharingInfo']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        // dispatch(startDataSharingInfo());
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.response){
                // dispatch(completeDataSharingInfo(xhrResponse.data.response));
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}

export const confirmDataSharing = (setting_data_sharing) => {    
    const params = constructParams(
        {
            url: `${CONFIG['api']['confirmDataSharing']['url']}?setting_data_sharing=${setting_data_sharing}`,
            method: CONFIG['api']['confirmDataSharing']['method']
        },
        {}
    )
    return (dispatch, getState) => {
        return buildAxios(dispatch, getState).request(params)
        .then((xhrResponse) => {
            if(xhrResponse.status == 200 && xhrResponse.data.response){
                return xhrResponse.data.response;
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
}