import {combineReducers} from 'redux';

import {appReducer} from './app';
import {feedReducer} from './feed';
import {postReducer} from './post';
import {boothReducer} from './booth';
import {memoryReducer} from './memory';
import {configReducer} from './config';
import {userReducer} from './user';
import {topicReducer} from './topic';
import {composeReducer} from './compose';
import {searchReducer} from './search';
import {onboardingReducer} from './onboarding';
import {staticPageReducer} from './staticPage';
import {trackerReducer} from './tracker';
import {contestReducer} from './contest';
import {videosReducer} from './video';
import {rewardReducer} from './reward';
import {covidLandingReducer} from './covidLanding';
import {covidCategoryQnAReducer} from './covidCategoryQnA';
import {covidAnswerReducer} from './covidAnswer';
import {foodReducer} from './food';
import {recipeReducer} from './recipe';
import {sitemapReducer} from './sitemap';
import { contentHubReducer } from './contentHub';
import { contentHubDetailPageReducer } from './contentHubDetailPage';
import { authorArticle } from './authorArticle';
import { blockUserList } from './blockUserList';
/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 * */

const allReducers = combineReducers({
    appReducer,
    feedReducer,
    postReducer,
    boothReducer,
    memoryReducer,
    configReducer,
    userReducer,
    topicReducer,
    composeReducer,
    searchReducer,
    onboardingReducer,
    staticPageReducer,
    trackerReducer,
    contestReducer,
    videosReducer,
    rewardReducer,
    covidLandingReducer,
    covidCategoryQnAReducer,
    covidAnswerReducer,
    foodReducer,
    recipeReducer,
    sitemapReducer,
    contentHubReducer,
    contentHubDetailPageReducer,
    authorArticle,
    blockUserList
});

export default allReducers;
