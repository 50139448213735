/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

const INITIAL_STATE = {
};

export const onboardingReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        default:
            return state
    }
};
