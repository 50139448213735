/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        type: '',
        calendarDescription: '',
        calendarList: [],
        pregnancyWeek: null,
        pregnancyViewContent: null,
        babyViewContent: null,
        babyMonth: null
    };
}

const INITIAL_STATE = IS();

export const trackerReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_TRACKER_CALENDAR_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_TRACKER_CALENDAR_FETCH':
            return {
                ...state,
                isLoading: false,
                type: action.payload.response.type,
                calendarDescription: action.payload.response.description,
                calendarList: action.payload.response.list
            }
        case 'START_TRACKER_PREGNANCY_DETAILS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_TRACKER_PREGNANCY_DETAILS_FETCH':
            return {
                ...state,
                isLoading: false,
                pregnancyWeek: action.payload.week,
                pregnancyViewContent: action.payload.response                
            }
        case 'START_TRACKER_BABY_DETAILS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_TRACKER_BABY_DETAILS_FETCH':
            return {
                ...state,
                isLoading: false,
                babyMonth: action.payload.month,
                babyViewContent: action.payload.response                
            }
        default:
            return state
    }
};
