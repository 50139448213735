/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        topicList: [],
        topicRelatedList: []
    };
}

const INITIAL_STATE = IS();

export const topicReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_TOPIC_LIST_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_TOPIC_LIST_FETCH':
            return {
                ...state,
                isLoading: false,
                topicList: action.payload.response.groups
            }
        case 'COMPLETE_TOPIC_RELATED_LIST_FETCH':
            return {
                ...state,
                topicRelatedList: action.payload.response.groups
            }
        default:
            return state
    }
};
