/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
import update from 'react-addons-update';
import {findIndexById} from '../helpers';

function IS() {
    return {
        type: '',
        id: null,
        isLoading: false,
        error: null,
        data: {}
    };
}

const INITIAL_STATE = IS();

export const postReducer = (state = INITIAL_STATE, action) => {
    let subIndex, commentIndex;

    switch (action.type) {
        case 'START_POST_FETCH':
            return {
                ...state,
                isLoading: true
            }       
        case 'COMPLETE_POST_FETCH':
            return {
                ...state,
                isLoading: false,
                type: action.payload.response.topic ? action.payload.response.topic.type : '',
                id: action.payload.response.topic ? action.payload.response.topic.id : null,
                data: action.payload.response,
                error: action.payload.response ? null : action.payload.message
            }
        case 'SHOW_INVALID_MESSAGE':
            return {
                ...state,
                isLoading: false,
                error: action.payload.message
            }
        case 'START_POST_POLL_VOTE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_POLL_VOTE':
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    already_voted: 1,
                    ...action.payload.response
                }
            }
        case 'START_POST_QUESTION_EDIT':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_QUESTION_EDIT':
            return {
                ...state,
                isLoading: false,
                error: action.payload.response ? null : action.payload.message,
                data: action.payload.response.feed[0]
            }
        case 'START_POST_REPLY_EDIT':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_REPLY_EDIT':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                isLoading: false,
                error: action.payload.status ? null : action.payload.message,
                data: {
                    ...state.data,
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            $set: action.payload.response.replies[0]
                        }
                    })
                }
            }
        case 'START_POST_QUESTION_HIDE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_QUESTION_HIDE':
            return INITIAL_STATE;
        case 'START_POST_QUESTION_DELETE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_QUESTION_DELETE':
            return INITIAL_STATE;
        case 'START_POST_REPLY_DELETE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_REPLY_DELETE':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    more_reply_count: state.data.more_reply_count > 0 ? state.data.more_reply_count - 1 : 0,
                    topic: {...state.data.topic , reply_count: state.data.topic.reply_count - 1},
                    replies: [...state.data.replies.slice(0, subIndex), ...state.data.replies.slice(subIndex + 1)]
                }
            }
        case 'START_POST_REPLY_COMMENT_DELETE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_REPLY_COMMENT_DELETE':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            commentIndex = findIndexById(action.payload.commentId, state.data.replies[subIndex].comments);
            if(commentIndex < 0) return state;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            more_comment_count: {$set: state.data.replies[subIndex].more_comment_count > 0 ? state.data.replies[subIndex].more_comment_count - 1 : 0 },
                            comments: {$set: [...state.data.replies[subIndex].comments.slice(0, commentIndex), ...state.data.replies[subIndex].comments.slice(commentIndex + 1)]}
                        }
                    })
                }
            }
        case 'START_POST_QUESTION_REPLY':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_QUESTION_REPLY':
            return {
                ...state,
                isLoading: false,
                error: action.payload.status ? null : action.payload.message,
                data: {
                    ...state.data,
                    // more_reply_count: state.data.more_reply_count + 1,
                    topic: {...state.data.topic , reply_count: action.payload.response.replies[0].reply_count},
                    replies: [...action.payload.response.replies, ...state.data.replies]
                }
            }
        case 'COMPLETE_POST_QUESTION_LIKE':
            return {
                ...state,
                data: {
                    ...state.data,
                    likes: {count: action.payload.postLikeCount + 1, liked: action.payload.liked}
                }
            }
        case 'COMPLETE_POST_QUESTION_UNLIKE':
            return {
                ...state,
                data: {
                    ...state.data,
                    likes: {count: action.payload.postLikeCount - 1, liked: action.payload.liked}
                }
            }
        case 'COMPLETE_POST_REPLY_LIKE':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                data: {
                    ...state.data,
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            likes: {$set: {...action.payload.likes}},
                            ...(action.payload.dislikes ? {dislikes: {$set: {...action.payload.dislikes}}} : {})
                        }
                    })
                }                
            }
        case 'COMPLETE_POST_REPLY_DISLIKE':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                data: {
                    ...state.data,
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            likes: {$set: {...action.payload.likes}},
                            ...(action.payload.dislikes ? {dislikes: {$set: {...action.payload.dislikes}}} : {})
                        }
                    })
                }                
            }
        case 'START_POST_REPLY_COMMENT_SAVE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_REPLY_COMMENT_SAVE':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                isLoading: false,
                error: action.payload.status ? null : action.payload.message,
                data: {
                    ...state.data, 
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            more_comment_count: {$set: action.payload.response.more_comment_count},
                            comments: {$set: action.payload.response.comments}
                        }
                    })
                }

            }
        case 'START_POST_REPLIES_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_REPLIES_FETCH':
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    more_reply_count: action.payload.response.more_reply_count,
                    replies: action.payload.isReset ? [...action.payload.response.replies] : [...state.data.replies, ...action.payload.response.replies]
                }
            }
        case 'START_POST_COMMENTS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_POST_COMMENTS_FETCH':
            subIndex = findIndexById(action.payload.replyId, state.data.replies);
            if(subIndex < 0) return state;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data, 
                    replies: update(state.data.replies, {
                        [subIndex]: {
                            more_comment_count: {$set: action.payload.response.more_comment_count},
                            comments: {$set: [...action.payload.response.comments, ...state.data.replies[subIndex].comments]}
                        }
                    })
                }

            }
        case 'COMPLETE_POST_ACTIONS_UPDATE':
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    actions: action.payload.response.actions
                }
            }
        case 'RESET_POST_LOADER':
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
};