/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        linkPreview: {
            linkData: null,
            type: null,
            url: ''
        },
        topicSuggestions: []
    };
}

const INITIAL_STATE = IS();

export const composeReducer = (state = INITIAL_STATE, action) => { 
    switch (action.type) {
        case 'START_COMPOSE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_COMPOSE':
            return {
                ...state,
                isLoading: false
            }
        case 'COMPLETE_LINK_PREVIEW_FETCH':
            return {
                ...state,                
                linkPreview: {
                    linkData: action.payload.response.link_data,
                    type: action.payload.response.type,
                    url: action.payload.response.link_data.url
                }
            }
        case 'COMPLETE_LINK_PREVIEW_RESET':
            return {
                ...state,                
                linkPreview: {
                    linkData: null,
                    type: null,
                    url: ''
                }
            }
        case 'START_TOPIC_SUGGESTIONS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_TOPIC_SUGGESTIONS_FETCH':
            return {
                ...state,
                isLoading: false,
                topicSuggestions: action.payload.response.suggested_groups
            }
        case 'RESET_COMPOSE':
            return INITIAL_STATE
        default:
            return state
    };
}
