import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash/throttle'
// import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { loadState, saveState } from './sessionStorage';
import allReducers from '../reducers/index';

export default function configureStore(serverState) {
    const enhancers = [];
    const middleware = [
        thunk,
      // routerMiddleware(history)
    ];

    if (process.env.REACT_APP_ENV === 'development' && typeof window != 'undefined') {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    )

    const persistedState = loadState(serverState);

    const store = createStore(
        allReducers,
        persistedState,
        composedEnhancers
    )

    store.subscribe(throttle(() => {
        saveState({
            userReducer: store.getState().userReducer,
        });
    }, 1000));

    return store;
}
