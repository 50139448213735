/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        activity : {}
    };
}

const INITIAL_STATE = IS();

export const contentHubDetailPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'START_CONTENTHUBDETAILPAGE_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_CONTENTHUBDETAILPAGE_FETCH':
            return {
                ...state,
                isLoading: false,
                activityData: action.payload
            }
        default:
            return state
    }
};
