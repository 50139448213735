/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */

function IS() {
    return {
        isLoading: false,
        page: 1,
        ifServerFilled: false,
        list: [],
        contestId: null,
        details: null,
        moreList: []
    };
}

const INITIAL_STATE = IS();

export const contestReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'START_CONTEST_LIST_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_CONTEST_LIST_FETCH':
            return {
                ...state,
                isLoading: false,
                page: action.payload.page,
                ifServerFilled: action.payload.ifServerFilled,
                list: [...(action.payload.shouldReset ? action.payload.response.contests : (action.payload.response.contests ? [...state.list, ...action.payload.response.contests] : [...state.list]))]
            }
        case 'START_CONTEST_DETAILS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_CONTEST_DETAILS_FETCH':
            return {
                ...state,
                isLoading: false,
                contestId: action.payload.response.contest.id,
                details: action.payload.response.contest
            }
        case 'COMPLETE_CONTEST_MORE_LIST_FETCH':
            return {
                ...state,
                isLoading: false,
                moreList: [...action.payload.response.contests]
            }
        default:
            return state
    }
};
